/*From Live-Page - compiled to .scss on 5th July 2017*/

//Vendor -->
@import "../../../Vendor/QTip2/jquery.qtip.min";
@import "../../../Vendor/PrettyPhoto/css/prettyPhoto";
@import "../../../Vendor/JQueryUI/jquery-ui-1.10.1.custom/css/smoothness/jquery-ui-1.10.1.custom.min";
//<-- Vendor

//Import Partials
@import "Partials/Responsive";
@import "Variables/fonts";
@import "Variables/colors";
@import "Partials/Types";
@import "Partials/Buttons";
@import "Partials/Contact";
@import "Partials/Content/Content";
@import "Partials/Forms";
@import "Partials/Footer";
@import "Partials/GoogleMaps";
@import "Partials/Header/Header";
@import "OwlSlider/owl.carousel";
@import "OwlSlider/owl.theme.default";
@import "Partials/Nav/Nav";
@import "Partials/Newsletter";
@import "Partials/Tables";
@import "Partials/Room/Room";
//dirs21 -->
@import "Partials/dirs21";
//<--


//data-uris
$data_uri_0: "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjEuMCIgeDI9IjAuNSIgeTI9IjAuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzgwYTUwNiIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2IzY2EwMiIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==";

//@extend-elements
//original selectors
//header.header:after, header.header:before
%extend_1 {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
}



address {
  color: inherit;
  font: inherit;
}

body {
  font: inherit;
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: Verdana, Arial, sans-serif;
  font-size: 12px;
  line-height: 18px;
  color: $color_peat_approx;
  @media (max-width: $screen-xs-max) {
    font-size: 14px;
  }
}

div {
  color: inherit;
  font: inherit;
  &.page {
    min-height: 100%;
    height: auto !important;
    position: relative;
  }
  &.inner {
    margin: 0 auto;
    height: 100%;
    position: relative;
  }
}

img {
  vertical-align: top;
  border: 0;
  max-width: 100%;
}

.csc-textpic-border img {
  box-sizing: border-box;
  border: 3px solid $color_soft_peach_approx;
}

.startpage-template .csc-textpic-border img {
  border: none;
}

#c176 img {
  margin-bottom: 5px;
  margin-top: 5px;
}

object {
  vertical-align: top;
}

.left {
  float: left;
}

.right {
  float: right;
}

.clear {
  clear: both;
}

.clearfix {
  display: inline-block;
  &:after {
    clear: both;
    content: ".";
    display: block;
    height: 0;
    line-height: 0;
    visibility: hidden;
  }
}

.accessibility {
  display: none;
}

nav,
article,
figure,
figcaption,
hgroup,
aside,
section {
  display: block;
}

html {
  height: 100%;
}
@media(min-width: 0) and(max-width: $screen-xs-max) {
  .hide-mobile {
    display: none;
  }
}
@media(min-width: $screen-sm) and(max-width: $screen-sm-max) {
  .hide-tablet {
    display: none;
  }
}
@media(min-width: $screen-md) {
  .hide-desktop {
    display: none;
  }
}
html[xmlns] .clearfix {
  display: block;
}

* html .clearfix {
  height: 1%;
}

.unselectable * {

}

.divider {
  margin-top: 20px;
  margin-bottom: 25px;
}

.fr-language,
.en-language {
  header.header nav.main-navigation ul li a {
    @media (max-width: 870px) {
      font-size: 10px;
    }
  }
}
