nav {
  &.mobile-navigation {
    display: none;
  }
  &.breadcrumb-navigation {
    clear: both;
    top: -30px;
    margin-bottom: -25px;
    position: relative;
    z-index: 4;
    margin-left: 0;
    ul li {
      background: none!important;
      padding: 0!important;
      float: left;
      a {
        font-size: 11px;
        color: $color_judge_gray_approx;
        text-decoration: underline;
        margin: 0 5px;
        &:hover {
          text-decoration: none;
        }
      }
      &.first a {
        margin-left: 0;
      }
      &.last a {
        text-decoration: none;
      }
    }
  }
}
body.fr-language header.header nav.main-navigation ul li a {
  padding-left: 7px;
  padding-right: 7px;
}

.sub-navigation li {
  display: block;
}