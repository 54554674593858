@import 'core';
@import 'animate';
@import 'autoheight';
@import 'lazyload';
@import 'video';

.tx-owl-slider {
  max-width: 1124px;
  height: auto;
  margin: -40px auto 0;
  padding: 0 40px;
  .owl-carousel {
    background-color: $white;
    border: solid 3px $white;
    box-shadow: 0 1px 10px rgba(1, 1, 1, 0.3);
    box-sizing: border-box;
  }
}

@media (max-width: $screen-sm-max) {
  .tx-owl-slider {
    margin-top: -17px;
  }
}

.owl-carousel .owl-nav.disabled {
  display: block;
}

.owl-carousel .owl-nav .owl-prev {
  position: absolute;
  top: 177px;
  left: -26px;
  width: 43px;
  height: 43px;
  z-index: 5;
  cursor: pointer;
  background: url("../Image/Desktop/Plugin/HeaderSlider/Previous.png") no-repeat;
}

.owl-carousel .owl-nav .owl-next {
  position: absolute;
  top: 177px;
  right: -26px;
  width: 43px;
  height: 43px;
  z-index: 5;
  cursor: pointer;
  background: url("../Image/Desktop/Plugin/HeaderSlider/Next.png") no-repeat;
}

@media (max-width: $screen-sm-max) {
  .owl-carousel .owl-nav .owl-next,
  .owl-carousel .owl-nav .owl-prev {
    border: none;
    &:before {
      top: 117px;
    }
  }
}

.owl-carousel .owl-dots {
  position: absolute;
  right: 62px;
  bottom: -24px;
  z-index: 5;
}

.owl-dot {
  display: inline-block;
  &.active span {
    background-image: url("../Image/Desktop/Plugin/HeaderSlider/PositionActive.png");
  }
  span {
    background-image: url("../Image/Desktop/Plugin/HeaderSlider/Position.png");
    margin-right: 2px;
    float: left;
    width: 28px;
    height: 30px;
    cursor: pointer;
  }
}