//##### - Room-Detail - #####
section.content div.main-content div.csc-default-introduction .room-detail {
  margin-top: 10px;
  .csc-default:first-of-type .divider {
    margin: 0;
  }

  h1, h2 {
    margin: 0 0 16px;
  }
  .room-button-wrapper {
    display: inline-block;
  }
  .room-price {
    margin-top: 0;
  }

  .two-cols {
    max-width: 47%;
    max-height: 242px;
    display: inline-block;
    margin-bottom: 20px;
    &:nth-of-type(2n+1) {
      margin-right: 3%;
    }
  }
}

//##### - Room-Previews - #####
section.content div.main-content .csc-default {
  &:first-of-type {
    .room-preview {
      margin-top: 10px;
    }
  }
}

section.content div.main-content div.csc-default-introduction .room-preview {
  margin: 0 0 30px;
  max-width: 400px;
  margin-right: 5%;
  h2 {
  }

}

.main-content .shortcut {
  .csc-default {
    display: inline-block;
    width: 100%;
  }
}
