nav {
  //.mobile-navigation {
  //  background: #f5efea;
  //  margin: 7px 0 0 0;
  //  a.mobile-reserve {
  //    display: block;
  //    text-align: center;
  //    border-radius: 0;
  //    border-right: none;
  //    border-left: none;
  //    margin-top: 5px
  //  }
  //  > ul {
  //    padding: 17px 0 0 0;
  //    width: 90%;
  //    margin: 0 auto;
  //    display: block;
  //    > li {
  //      display: block;
  //      float: left;
  //      min-width: 33%;
  //      &.directions a {
  //        background-image: url(../Image/Mobile/Header/Directions.png);
  //      }
  //      &.contact a {
  //        background-image: url(../Image/Mobile/Header/Contact.png);
  //      }
  //      > a, .current-language {
  //        display: block;
  //        background: no-repeat center top;
  //        min-width: 60px;
  //        height: 20px;
  //        padding: 66px 0 0 0;
  //        font-family: "Cronos Pro 1166369";
  //        font-size: 16px;
  //        line-height: 20px;
  //        color: #786950;
  //        text-align: center;
  //        cursor: pointer;
  //      }
  //      &.language {
  //        position: relative;
  //        ul .language-selection {
  //          display: none;
  //          padding: 15px 15px 15px 15px;
  //          background: #f5efea;
  //          width: 60px;
  //          height: 140px;
  //          position: absolute;
  //          top: -170px;
  //          left: 50%;
  //          margin-left: -45px;
  //          z-index: 999;
  //          box-shadow: 5px 0 8px -5px rgba(0, 0, 0, 0.2), -5px 0 8px -5px rgba(0, 0, 0, 0.2), 0 -5px 8px -5px rgba(0, 0, 0, 0.2);
  //          li a {
  //            display: block;
  //            width: 60px;
  //            height: 60px;
  //            padding-bottom: 15px;
  //            background: no-repeat;
  //            &.german {
  //              background-image: url(../Image/Mobile/Header/GermanActive.png);
  //            }
  //            &.english {
  //              background-image: url(../Image/Mobile/Header/EnglishActive.png);
  //            }
  //            &.french {
  //              background-image: url(../Image/Mobile/Header/FrenchActive.png);
  //            }
  //          }
  //        }
  //        .current-language {
  //          &.german {
  //            background-image: url(../Image/Mobile/Header/GermanActive.png);
  //          }
  //          &.english {
  //            background-image: url(../Image/Mobile/Header/EnglishActive.png);
  //          }
  //          &.french {
  //            background-image: url(../Image/Mobile/Header/FrenchActive.png);
  //          }
  //
  //        }
  //      }
  //    }
  //  }
  //}

  &.breadcrumb-navigation {
    clear: both;
    top: -12px;
    margin-bottom: -45px;
    position: relative;
    z-index: 4;
    display: none;
    ul li {
      float: left;
      a {
        font-size: 11px;
        color: $color_peat_approx;
        text-decoration: underline;
        margin: 0 5px;
        &:hover {
          text-decoration: none;
        }
      }
      &.first a {
        margin-left: 0;
      }
      &.last a {
        text-decoration: none;
      }
    }
  }

}

body {
  &.fr-language header.header nav.main-navigation ul li a {
    padding-left: 6px;
    padding-right: 6px;
  }
  &.subpage-template section.content {
    nav.sub-navigation {
      margin: 0 3% 40px;
      width: 22%;
      float: left;
    }
  }
}

@import "../../Mmenu/_mobileMenu.scss";
