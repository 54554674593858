header.header {
  height: 198px;

  &:before {
    height: 200px;
  }

  div.inner {
    width: 100%;
    max-width: 990px;
  }
}

header.header nav {
  &.main-navigation {
    position: absolute;
    left: 232px;
    bottom: 30px;

    ul {
      li {
        float: left;

        a {
          font-family: $font_2;
          padding: 30px 11px 27px;
          display: block;
          font-weight: bold;
          color: $color_peat_approx;
          background: url(../Image/Desktop/Header/Spacer.png) no-repeat right bottom;
          z-index: 3;
          position: relative;
          letter-spacing: 1px;
          text-shadow: 0 1px 2px $white;

          &:hover {
            color: $color_limeade_approx;
          }
        }

        &.active {
          background: url(../Image/Desktop/Header/NavigationActive.png) no-repeat center -10px;

          a {
            color: $color_limeade_approx;
          }
        }

        &:hover {
          background: url(../Image/Desktop/Header/NavigationActive.png) no-repeat center -10px;
        }

        &.first {
          a {
            padding-left: 15px;
          }
        }

        &.last a {
          background: none;
        }
      }

      &.level-2 {
        display: none;
      }
    }

    span.label {
      display: none;
    }
  }
}

header.header nav.top-navigation {
  position: absolute;
  right: 0;
  top: 4px;
  z-index: 3;

  ul li {
    float: left;

    a {
      display: block;
      width: 26px;
      height: 26px;
      margin-left: 10px;
      background: no-repeat;

      &.home {
        margin-right: 10px;
        background-image: url(../Image/Desktop/Icon/Home.png);

        &:hover {
          background-image: url(../Image/Desktop/Icon/HomeHover.png);
        }
      }

      &.german {
        background-image: url(../Image/Desktop/Icon/German.png);

        &:hover {
          background-image: url(../Image/Desktop/Icon/GermanActive.png);
        }

        &.active {
          background-image: url(../Image/Desktop/Icon/GermanActive.png);
        }
      }

      &.english {
        background-image: url(../Image/Desktop/Icon/English.png);

        &:hover {
          background-image: url(../Image/Desktop/Icon/EnglishActive.png);
        }

        &.active {
          background-image: url(../Image/Desktop/Icon/EnglishActive.png);
        }
      }

      &.french {
        background-image: url(../Image/Desktop/Icon/French.png);

        &:hover {
          background-image: url(../Image/Desktop/Icon/FrenchActive.png);
        }

        &.active {
          background-image: url(../Image/Desktop/Icon/FrenchActive.png);
        }
      }
    }
  }
}

div {
  &.header-content {
    min-height: 15vw;

    .hide-mobile {
      min-height: 34vw;
    }

    .empty-header {
      height: 50px;
    }

    .csc-frame-default div.csc-textpic {
      width: 92%;
      max-width: 1124px;
      margin: 0 auto;
      position: relative;
      overflow: visible;
      z-index: 2;
      margin-top: -40px;

      img {
        width: 100%;
        background: $white;
        border: solid 3px $white;
        box-shadow: 0 1px 10px $color_black_30_approx;
      }

      div.csc-textpic-imagewrap {
        overflow: visible;
      }
    }
  }
}

header.header div.branding {
  width: 230px;
  height: 200px;
  z-index: 5;
  position: absolute;
  background-color: $white;
  text-align: center;

  a.logo {
    height: 100%;
    width: 170px;
    display: block;
    margin: auto;
    background: no-repeat center url(../Image/Desktop/Header/Logo.png);
    background-position-y: 30px;
    background-size: contain;
  }
}

header.header div.branding div.reserve {
  position: absolute;
  top: 100%;
  z-index: 5;
  text-align: left;

  h6 {
    width: 170px;
    position: relative;
    bottom: 30px;
    margin: auto;
    padding: 4px 0 4px;
  }

  div {
    &.reserve-content {
      overflow: hidden;
      height: 0;
      background: $white;
      border-right: 1px solid $color_gray_nurse_approx;
      border-left: 1px solid $color_gray_nurse_approx;

      fieldset {
        border-top: 1px solid $color_dawn_pink_approx;
        margin-top: 15px;
        padding: 15px 11px 5px;
      }

      input[type=text] {
        width: 180px;
      }

      div.button-wrapper {
        border-top: 1px solid $color_dawn_pink_approx;
        text-align: center;
        padding: 3px 0 18px;
      }
    }

    &.reserve-button {
      display: block;
      height: 32px;
      width: 232px;
      background: url(../Image/Desktop/Header/ReserveBottom.png) no-repeat -1px 0;
      text-align: center;
      color: $color_donkey_brown_approx;
      cursor: pointer;

    }
  }

  &.open div.reserve-button div span {
    font-weight: bold;
    display: inline;
  }

}

