div.csc-textpic-imagecolumn.csc-textpic-lastcol,
td.csc-textpic-imagecolumn.csc-textpic-lastcol .csc-textpic-image {
  margin-right: 0;
}

div.csc-textpic {
  div.csc-textpic-imagecolumn {
    float: left;
    .csc-textpic-image {
      margin-bottom: 20px;
    }
  }
}

div.csc-textpic,
div.csc-textpic div.csc-textpic-imagerow,
ul.csc-uploads li {
  overflow: hidden;
}

div.csc-textpic-imagecolumn,
td.csc-textpic-imagecolumn .csc-textpic-image {
  margin-right: 20px;
}

.csc-textpic-caption {
  text-align: left;
  caption-side: bottom;
}

div.csc-textpic-intext-right .csc-textpic-imagewrap,
div.csc-textpic-intext-right-nowrap .csc-textpic-imagewrap {
  margin-left: 20px;
  float: right;
}

section {
  &.content {
    margin: 40px 0 0;
    div.inner {
      width: 100%;
      max-width: 990px;
    }
    nav.sub-navigation {
      margin: 0 0 35px 0;
    }
    div {
      &.main-content {
        float: right;
        width: 740px;
        margin: 8px 0 0;
        min-height: 400px;
        aside.left-content {
          .csc-default {
            margin: 0 0 30px 0;
          }
        }
        div {
          &.empty-main-content {
            height: 200px;
          }
          &.csc-frame-default {
            clear: both;
          }
          &.csc-default-introduction > .csc-header {
            > h1 {
              &:after {
                color: $color_judge_gray_approx;
                filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=10);
                opacity: .1;
                height: 6px;
              }
            }
            h2 {
              color: $color_peat_approx;
              font-size: 26px;
              line-height: 26px;
              &:after {
                color: $color_judge_gray_approx;
                filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=10);
                opacity: .1;
                height: 6px;
              }
            }
            h3 {
              color: $color_peat_approx;
              font-size: 26px;
              line-height: 26px;
              &:after {
                color: $color_judge_gray_approx;
                filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=10);
                opacity: .1;
                height: 6px;
              }
            }
            p {
              font-family: $font_1;
              font-size: 20px;
              line-height: 30px;
              color: $color_donkey_brown_approx;
            }
          }
          &.csc-half-size {
            clear: none;
            width: 48.5%;
            margin-right: 3%;
            float: left;
            div.csc-textpic-border div.csc-textpic-image span.image:after {
              bottom: -7px;
              //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
              transform: scale(.5, .5);
            }
          }
          &.csc-half-size-last {
            margin-right: 0;
          }
          &.csc-textpic-border {
            div.csc-textpic-image span.image {
              position: relative;
              display: block;
              &:after {
                display: block;
                content: "";
                position: absolute;
                bottom: -10px;
                left: 50%;
                margin-left: -335px;
                width: 670px;
                height: 10px;
              }
            }
            &.csc-textpic-intext-right div.csc-textpic-image span.image:after {
              bottom: -7px;
              //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
              transform: scale(.5, .5);
            }
            &.csc-textpic-intext-left div.csc-textpic-image span.image:after {
              bottom: -7px;
              //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
              transform: scale(.5, .5);
            }
            &.csc-textpic-intext-right-nowrap div.csc-textpic-image span.image:after {
              bottom: -7px;
              //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
              transform: scale(.5, .5);
            }
            &.csc-textpic-intext-left-nowrap div.csc-textpic-image span.image:after {
              bottom: -7px;
              //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
              transform: scale(.5, .5);
            }
          }
          &.csc-textpic-intext-left-nowrap {
            .csc-textpic-imagewrap {
              float: left;
            }
            div.csc-textpic-text {
              margin-left: 380px;
            }
          }
          &.csc-textpic-intext-right-nowrap {
            .csc-textpic-imagewrap {
              float: right;
            }
            div.csc-textpic-text {
              margin-right: 380px;
            }
          }
          &.csc-textpic-imagecolumn span.image:after {
            display: none !important;
          }
        }
        p {
          margin-bottom: 10px;
          a {
            text-decoration: none;
            &:hover {
              color: $color_pistachio_approx;
            }
          }
        }
        ul {
          li {
            padding: 0 0 0 15px;
            background: url(../Image/Desktop/Icon/ListLevel1.png) no-repeat 0 6px;
            margin: 0 0 9px 0;
            ul {
              margin: 9px 0 0;
              li {
                background-image: url(../Image/Desktop/Icon/ListLevel2.png);
              }
            }
          }
          &.csc-menu li.active a {
            font-weight: bold;
          }
          &.special li {
            padding: 8px 0 8px 35px;
            margin: 0 0 2px 0;
            background: url(../Image/Desktop/Icon/ListSpecial.png) no-repeat 0 1px;
          }
          &.links li {
            background: url(../Image/Desktop/Icon/ListLinks.png) no-repeat 0 3px;
            a {
              text-decoration: underline;
              &:hover {
                text-decoration: none;
              }
            }
          }
        }
        h1 {
          position: relative;
          font-family: $font_0;
          font-size: 20px;
          font-weight: normal;
          line-height: 20px;
          color: $color_peat_approx;
          text-transform: uppercase;
          padding: 0 0 12px 0;
          margin: 0;
          &:after {
            content: attr(data-title);
            position: absolute;
            left: 0;
            top: 0;
            display: block;
            width: 100%;
            height: 5px;
            color: $color_judge_gray_approx;
            filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=30);
            opacity: .3;
          }
          a {
            color: $color_peat_approx;
          }
        }
        h2 {
          font-weight: 700;
          font-size: 18px;
          position: relative;
          font-family: $font_0;
          line-height: 20px;
          color: $color_peat_approx;
          text-transform: uppercase;
          padding: 0;
          margin: 0 0 5px 0;
          &:after {
            content: attr(data-title);
            position: absolute;
            left: 0;
            top: 0;
            display: block;
            width: 100%;
            height: 5px;
            //overflow: hidden;
            color: $color_judge_gray_approx;
            filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=30);
            opacity: .3;
          }
          a {
            color: $color_peat_approx;
          }
        }
        h3 {
          position: relative;
          font-family: $font_0;
          font-size: 20px;
          font-weight: normal;
          line-height: 20px;
          color: $color_peat_approx;
          text-transform: uppercase;
          padding: 0 0 12px 0;
          margin: 0 0 6px 0;
          &:after {
            content: attr(data-title);
            position: absolute;
            left: 0;
            top: 0;
            display: block;
            width: 100%;
            height: 5px;
            //overflow: hidden;
            color: $color_judge_gray_approx;
            filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=30);
            opacity: 0;
          }
          a {
            color: $color_peat_approx;
          }
        }
        h4 {
          font-weight: bold;
          margin-bottom: 9px;
        }
        h5 {
          font-weight: bold;
          margin-bottom: 9px;
        }
        caption.csc-textpic-caption {
          background: $color_soft_peach_approx;
          margin: 0 3px 0 2px;
          padding: 3px 9px 8px;
        }
        table.contenttable {
          width: 100%;
          border-collapse: collapse;
          overflow: auto;
          th {
            font-size: 14px;
            font-weight: bold;
            color: $color_olivetone_approx;
            padding: 8px 12px;
            border-right: 1px solid $color_olivetone_approx;
            position: relative;
            &:last-child {
              border-right: none;
            }
          }
          td {
            padding: 8px 12px;
            border-right: 1px solid $color_olivetone_approx;
            position: relative;
            &:last-child {
              border-right: none;
            }
          }
          tr {
            &:nth-child(even) {
              background: $white;
            }
            &:nth-child(odd) {
              background: $color_kidnapper_approx;
            }
          }
          thead tr {
            background: $white !important;
          }
        }
      }

      &.divider {
        border-top: 1px solid $color_dawn_pink_approx;
        height: 1px;
        hr {
          display: none;
        }
      }
    }

    aside.left-content {
      width: 230px;
      margin: 0 20px 0 0;
      margin-top: 95px;
      .csc-default {
        margin: 0 0 30px 0;
      }
      .bestpreis-sign {
        position: absolute;
        top: -70px;
        z-index: 5;
        left: 0;
      }
      p {
        margin-bottom: 0;
        margin-top: 10px;
      }
      h1 {
        position: relative;
        font-family: $font_0;
        font-size: 16px;
        font-weight: normal;
        line-height: 20px;
        color: $color_pistachio_approx;
        text-transform: uppercase;
        height: 20px;
        padding: 13px 0 12px;
        margin: 0 0 6px 0;
        &:after {
          content: "KONTAKT";
          content: attr(data-title);
          position: absolute;
          left: 0;
          top: 13px;
          display: block;
          width: 100%;
          height: 5px;
          //overflow: hidden;
          color: $color_olivetone_approx;
          opacity: 0;
        }
        &.header-icon-contact {
          padding-left: 50px;
          background-repeat: no-repeat;
          background-image: url(../Image/Desktop/Icon/Contact.png);
          &:after {
            left: 50px;
          }
        }
        &.header-icon-brochure {
          padding-left: 50px;
          background-repeat: no-repeat;
          background-image: url(../Image/Desktop/Icon/Brochure.png);
          &:after {
            left: 50px;
          }
        }
        &.header-icon-newsletter {
          padding-left: 50px;
          background-repeat: no-repeat;
          background-image: url(../Image/Desktop/Icon/Newsletter.png);
          &:after {
            left: 50px;
          }
        }
      }
      h2 {
        position: relative;
        font-family: $font_0;
        font-size: 16px;
        font-weight: normal;
        line-height: 20px;
        color: $color_pistachio_approx;
        text-transform: uppercase;
        height: 20px;
        padding: 13px 0 12px;
        margin: 0 0 6px 0;
        &:after {
          content: "KONTAKT";
          content: attr(data-title);
          position: absolute;
          left: 0;
          top: 13px;
          display: block;
          width: 100%;
          height: 5px;
          //overflow: hidden;
          color: $color_olivetone_approx;
          filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=30);
          opacity: .3;
        }
        &.header-icon-contact {
          padding-left: 50px;
          background-repeat: no-repeat;
          background-image: url(../Image/Desktop/Icon/Contact.png);
          &:after {
            left: 50px;
          }
        }
        &.header-icon-brochure {
          padding-left: 50px;
          background-repeat: no-repeat;
          background-image: url(../Image/Desktop/Icon/Brochure.png);
          &:after {
            left: 50px;
          }
        }
        &.header-icon-newsletter {
          padding-left: 50px;
          background-repeat: no-repeat;
          background-image: url(../Image/Desktop/Icon/Newsletter.png);
          &:after {
            left: 50px;
          }
        }
      }
      h3 {
        position: relative;
        font-family: $font_0;
        font-size: 14px;
        font-weight: normal;
        line-height: 20px;
        color: $color_olivetone_approx;
        text-transform: uppercase;
        height: 20px;
        padding: 13px 0 12px;
        margin: 0 0 6px 0;
        &:after {
          content: "KONTAKT";
          content: attr(data-title);
          position: absolute;
          left: 0;
          top: 13px;
          display: block;
          width: 100%;
          height: 5px;
          //overflow: hidden;
          color: $color_olivetone_approx;
          filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=30);
          opacity: 0;
        }
        &.header-icon-contact {
          padding-left: 50px;
          background-repeat: no-repeat;
          background-image: url(../Image/Desktop/Icon/Contact.png);
          background-position-y: 4px;
          &:after {
            left: 50px;
          }
        }
        &.header-icon-brochure {
          padding-left: 50px;
          background-repeat: no-repeat;
          background-image: url(../Image/Desktop/Icon/Brochure.png);
          &:after {
            left: 50px;
          }
        }
        &.header-icon-newsletter {
          padding-left: 50px;
          background-repeat: no-repeat;
          background-image: url(../Image/Desktop/Icon/Newsletter.png);
          &:after {
            left: 50px;
          }
        }
      }
      DIV.csc-textpic .csc-textpic-imagewrap .csc-textpic-image {
        margin-bottom: 0;
      }
    }
    p.green-bold, p.detail, span.detail {
      font-size: 14px;
      font-weight: bold;
      color: $color_pistachio_approx;
      padding-bottom: 3px;
    }
  }
}

.startpage-template section.content {
  margin-top: 90px;
  aside {
    .left-content {
      margin-top: 0;
      position: relative;
      top: 50px;
    }
  }
}

.startpage-template section.content div.main-content {
  margin-top: -40px;
}

.startpage-template section.content {
  .inner {
    margin-top: -10px;
  }
  aside.left-content .bestpreis-sign {
    position: absolute;
    top: -120px;
  }
}

section.content .inner {
  margin-top: -5px;
}


.csc-textpic-intext-right > .csc-textpic-imagewrap {
  width: 50%;
  .csc-textpic-imagecolumn {
    width: 47%;
  }
}

.csc-header {
  margin: 0;
}

.csc-header-n1 {
  margin: 0;
}