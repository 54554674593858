@import "General";

@media (max-width: ($screen-xs-max)) {
  @import "Mobile";
}

@media(min-width: $screen-sm) and(max-width: $screen-sm-max) {
  @import "Tablet";
}

@media(min-width: $screen-md) {
  @import "Desktop";
}
//Check