section.content {
  position: relative;
}

.startpage-template footer {
  margin-top: 25px;
}

section.content div.main-content {
  table.contenttable {
    display: block;
    overflow: scroll;
  }
  div.csc-default-introduction {
    h1 {
      color: $color_peat_approx;
      font-size: 23px;
      font-weight: 700;
      line-height: 26px;
      padding: 0;
      margin-bottom: 20px;
    }
  }
}

section {
  &.content {
    nav.sub-navigation {
      margin: 0 0 35px 18px;
      width: 230px;
      > ul {
        position: relative;
        top: 57px;
        li {
          a {
            display: block;
            font-family: $font_1;
            font-size: 15px;
            font-weight: bold;
            color: $color_donkey_brown_approx;
            border-bottom: 1px solid $color_stonewall_30_approx;
            padding: 13px 5px 13px 10px;
            background-color: $color_soft_peach_approx;
            &:hover {
              color: $color_citron_approx;
              i {
                color: $color_citron_approx;
              }
            }
          }
          &.active {
            ul li {
              font-weight: normal;
              &.active {
                ul li {

                }
              }
            }
            ul {
              li.active a {
                font-weight: bold;
              }
              a {
                font-weight: normal;
                &:hover {
                  color: $color_citron_approx;
                }
              }
            }
          }
        }
      }
    }

    aside.left-content {
      width: 230px;
      margin: 0 20px 0 0;
      margin-top: 95px;
      .csc-default {
        margin: 0 0 30px 18px;
      }
      .bestpreis-sign {
        position: absolute;
        top: -70px;
        left: 18px;
        z-index: 5;
      }
      p {
        margin-bottom: 0;
        margin-top: 10px;
      }
      h1 {
        position: relative;
        font-family: $font_0;
        font-size: 16px;
        font-weight: normal;
        line-height: 20px;
        color: $color_pistachio_approx;
        text-transform: uppercase;
        height: 20px;
        padding: 13px 0 12px;
        margin: 0 0 6px 0;
        &:after {
          content: "KONTAKT";
          content: attr(data-title);
          position: absolute;
          left: 0;
          top: 13px;
          display: block;
          width: 100%;
          height: 5px;
          //overflow: hidden;
          color: $color_olivetone_approx;
          filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=30);
          opacity: .3;
        }
        &.header-icon-contact {
          padding-left: 50px;
          background-repeat: no-repeat;
          background-image: url(../Image/Desktop/Icon/Contact.png);
          &:after {
            left: 50px;
          }
        }
        &.header-icon-brochure {
          padding-left: 50px;
          background-repeat: no-repeat;
          background-image: url(../Image/Desktop/Icon/Brochure.png);
          &:after {
            left: 50px;
          }
        }
        &.header-icon-newsletter {
          padding-left: 50px;
          background-repeat: no-repeat;
          background-image: url(../Image/Desktop/Icon/Newsletter.png);
          &:after {
            left: 50px;
          }
        }
      }
      h2 {
        position: relative;
        font-family: $font_0;
        font-size: 16px;
        font-weight: normal;
        line-height: 20px;
        color: $color_pistachio_approx;
        text-transform: uppercase;
        height: 20px;
        padding: 13px 0 12px;
        margin: 0 0 6px 0;
        &:after {
          content: "KONTAKT";
          content: attr(data-title);
          position: absolute;
          left: 0;
          top: 13px;
          display: block;
          width: 100%;
          height: 5px;
          //overflow: hidden;
          color: $color_olivetone_approx;
          filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=30);
          opacity: .3;
        }
        &.header-icon-contact {
          padding-left: 50px;
          background-repeat: no-repeat;
          background-image: url(../Image/Desktop/Icon/Contact.png);
          &:after {
            left: 50px;
          }
        }
        &.header-icon-brochure {
          padding-left: 50px;
          background-repeat: no-repeat;
          background-image: url(../Image/Desktop/Icon/Brochure.png);
          &:after {
            left: 50px;
          }
        }
        &.header-icon-newsletter {
          padding-left: 50px;
          background-repeat: no-repeat;
          background-image: url(../Image/Desktop/Icon/Newsletter.png);
          &:after {
            left: 50px;
          }
        }
      }
      h3 {
        position: relative;
        font-family: $font_0;
        font-size: 14px;
        font-weight: normal;
        line-height: 20px;
        color: $color_olivetone_approx;
        text-transform: uppercase;
        height: 20px;
        padding: 13px 0 12px;
        margin: 0 0 6px 0;
        &:after {
          content: "KONTAKT";
          content: attr(data-title);
          position: absolute;
          left: 0;
          top: 13px;
          display: block;
          width: 100%;
          height: 5px;
          //overflow: hidden;
          color: $color_olivetone_approx;
          opacity: 0;
        }
        &.header-icon-contact {
          padding-left: 50px;
          background-repeat: no-repeat;
          background-image: url(../Image/Desktop/Icon/Contact.png);
          background-position-y: 4px;
          &:after {
            left: 50px;
          }
        }
        &.header-icon-brochure {
          padding-left: 50px;
          background-repeat: no-repeat;
          background-image: url(../Image/Desktop/Icon/Brochure.png);
          &:after {
            left: 50px;
          }
        }
        &.header-icon-newsletter {
          padding-left: 50px;
          background-repeat: no-repeat;
          background-image: url(../Image/Desktop/Icon/Newsletter.png);
          &:after {
            left: 50px;
          }
        }
      }
      DIV.csc-textpic .csc-textpic-imagewrap .csc-textpic-image {
        margin-bottom: 0;
      }
    }
  }
}

img[alt="barrierefrei"] {
  width: 100%;
  border: none;
}

img[alt="awards"] {
  width: 100%;
  border: none;
}

.main-content p {
  margin-bottom: 24px;
  margin-top: 0;
}

section.content div.main-content {
  div.csc-half-size {
    max-width: 370px;
  }
  div.csc-default-introduction {
    p {
      line-height: 26px;
      font-size: 18px;
      font-weight: bold;
      color: $color_peat_approx;
    }
  }
}

.bestpreis-sign {
  &:hover {
    filter: brightness(110%);
  }
}

section.content aside.left-content .icon-hotel-rating {
  padding-left: 50px;
  background-repeat: no-repeat;
  background-image: url(../Image/Desktop/Icon/Hotel_Bewertungen.png);
  background-position-y: 4px;
}

div.csc-textpic-border div.csc-textpic-imagewrap img {
  padding: 0px 0px;
}

div.csc-textpic-intext-right .csc-textpic-imagewrap {
  float: right;
  img {
    max-width: 250px;
  }
}

.csc-textpic-border picture {
  //border: solid 3px $color_soft_peach_approx!important;
}

.csc-textpic-border img {
  margin-bottom: 10px;
}

.startpage-template {
  .download {
    margin-bottom: 26px;
    margin-top: 14px;
  }
  section.content div.main-content div.csc-default {
    h1,
    h2,
    h3 {
      font-weight: bold;
      font-size: 16px;
      margin-bottom: 5px;
      color: $color_peat_approx;
    }
  }
}

.startpage-template div.main-content > .csc-default:first-of-type {
  h1 {
    line-height: 30px;
    margin-top: -10px;
    font-size: 23px !important;
    margin-bottom: 20px !important;
  }
  > p.bodytext {
    font-weight: normal !important;
    font-size: 20px !important;
    font-family: $font_0 !important;
    color: $color_peat_approx !important;
  }
}

section.content div.main-content p a.download:hover {
  color: #648006;
}

.left-content img {
  border: none;
  max-width: 230px;
}

.startpage-template {
  &:last-of-type {
    > img {
      height: auto;
      border: none;
    }
  }
}