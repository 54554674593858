//##### - Room-Detail - #####

//##### - Room-Previews - #####
section.content div.main-content .csc-default {
  &:first-of-type {
    .room-preview {
      margin-top: 10px;
    }
  }
}

.main-content .shortcut .csc-default {
  display: inline-block;
  width: 50%;
  img {
    height: 242px;
  }
}