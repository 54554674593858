

@media(min-width: $screen-sm) and(max-width: $screen-sm-max) {
  section {
    &#google-map {
      form {
        label {
          float: left;
          margin-right: 20px;
          &.street-field input[type=text] {
            width: 200px;
          }
          &.zipcode-field input[type=text] {
            width: 80px;
          }
          &.city-field input[type=text] {
            width: 190px;
          }
        }
        div.submit {
          float: left;
          position: relative;
          top: -11px;
        }
      }
      div#google-map-canvas {
        margin: 20px 0 0;
        height: 400px;
      }
    }
  }
}

@media(min-width: $screen-md) {
  section {
    &#google-map {
      form {
        label {
          float: left;
          margin-right: 20px;
          &.street-field input[type=text] {
            width: 200px;
          }
          &.zipcode-field input[type=text] {
            width: 80px;
          }
          &.city-field input[type=text] {
            width: 190px;
          }
        }
        div.submit {
          float: left;
          position: relative;
          top: -11px;
        }
      }
      div#google-map-canvas {
        margin: 20px 0 0;
        height: 400px;
      }
    }
  }
}