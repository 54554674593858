header.header.header-content div {
  &.empty-header {
    height: 50px;
  }
  &.csc-frame-default div.csc-textpic {
    width: 92%;
    max-width: 1124px;
    margin: 0 auto;
    position: relative;
    overflow: visible;
    z-index: 2;
    margin-top: -17px;
    img {
      background: $white;
      border: solid 3px $white;
      box-shadow: 0 1px 10px $color_black_30_approx;
      width: 100%;
    }
    div.csc-textpic-imagewrap {
      overflow: visible;
    }
  }
}

.startpage-template .header-content {
  position: relative;
  top: 0;
}

header.header {
  height: 131px;
  &:before {
    height: 133px;
  }
  nav {
    &.main-navigation {
      position: absolute;
      left: 0;
      bottom: 0;
      ul {
        li {
          //float: left;
          a {
            font-family: $font_1;
            display: block;
            color: $color_peat_approx;
            background: url(../Image/Desktop/Header/Spacer.png) no-repeat right bottom;
            z-index: 3;
            position: relative;
            letter-spacing: 1px;
            text-shadow: 0 1px 2px $white;
            font-size: 12px;
            padding: 16px 8px 17px;
            &:hover {
              color: $color_limeade_approx;
            }
          }
          &.active {
            a {
              color: $color_limeade_approx;
            }
          }
          &.last a {
            background: none;
          }
        }
        &.level-2 {
          display: none;
        }
      }
      span.label {
        display: none;
      }
    }
    &.top-navigation {
      position: absolute;
      top: 8px;
      z-index: 3;
      right: 20px;
      ul li {
        float: left;
        a {
          display: block;
          margin-left: 10px;
          background: no-repeat;
          width: 41px;
          height: 41px;
          background-size: 35px;
          &.home {
            margin-right: 10px;
            background-image: url(../Image/Tablet/Icon/Home.png);
            background-size: 36px;
            &:hover {
              background-image: url(../Image/Tablet/Icon/Home.png);
            }
          }
          &.german {
            background-image: url(../Image/Tablet/Icon/German.png);
            &:hover {
              background-image: url(../Image/Tablet/Icon/GermanActive.png);
            }
            &.active {
              background-image: url(../Image/Tablet/Icon/GermanActive.png);
            }
          }
          &.english {
            background-image: url(../Image/Tablet/Icon/English.png);
            &:hover {
              background-image: url(../Image/Tablet/Icon/EnglishActive.png);
            }
            &.active {
              background-image: url(../Image/Tablet/Icon/EnglishActive.png);
            }
          }
          &.french {
            background-image: url(../Image/Tablet/Icon/French.png);
            &:hover {
              background-image: url(../Image/Tablet/Icon/FrenchActive.png);
            }
            &.active {
              background-image: url(../Image/Tablet/Icon/FrenchActive.png);
            }
          }
        }
      }
    }
  }
  div.branding {
    transform: scale(.75, .75);
    top: -19px;
    width: 232px;
    height: 140px;
    z-index: 5;
    margin: auto;
    left: -10px;
    position: absolute;
    background-color: $white;
    padding-top: 15px;
    text-align: center;
    a.logo {
      width: 189px;
      height: 123px;
      display: inline-block;
      margin-bottom: 16px;
      background: url(../Image/Desktop/Header/Logo.png);
    }
    div.reserve {
      position: relative;
      z-index: 5;
      text-align: left;
      h6 {
        cursor: pointer;
        font-weight: bold;
        color: $color_pistachio_approx;
        text-align: center;
        padding: 0;
      }
      div {
        &.reserve-content {
          //overflow: hidden;
          height: 0;
          background: $white;
          border-right: 1px solid $color_gray_nurse_approx;
          border-left: 1px solid $color_gray_nurse_approx;
          fieldset {
            border-top: 1px solid $color_dawn_pink_approx;
            margin-top: 15px;
            padding: 15px 11px 5px;
          }
          input[type=text] {
            width: 180px;
          }
          div.button-wrapper {
            border-top: 1px solid $color_dawn_pink_approx;
            text-align: center;
            padding: 3px 0 18px;
          }
        }
        &.reserve-button {
          display: block;
          height: 32px;
          width: 232px;
          background: url(../Image/Desktop/Header/ReserveBottom.png) no-repeat -1px 0;
          text-align: center;
          color: $color_donkey_brown_approx;
          cursor: pointer;
          div {
            height: 25px;
            span {
              display: none;
            }
          }
        }
      }
      &.open div.reserve-button div span {
        font-weight: bold;
        display: inline;
      }
    }
  }
}

div {
  &.header-content {
    min-height: 15vw;
    top: 23px;
    div {
      &.empty-header {
        height: 50px;
      }
      &.hide-mobile {
        min-height: 33vw;
      }
      &.csc-default div.csc-textpic {
        width: 92%;
        max-width: 1124px;
        margin: 0 auto;
        position: relative;
        overflow: visible;
        z-index: 2;
        margin-top: -16px;
        img {
          background: $white;
          width: 100%;
          border: solid 3px $white;
          box-shadow: 0 1px 10px $color_black_30_approx;
        }
        div.csc-textpic-imagewrap {
          overflow: visible;
        }
      }
    }
  }
}