/*
	jQuery.mmenu effects extension CSS
*/

@import "../../scss/import";


//	Zoom menu
.mm-menu.mm-fx-menu-zoom
{
	transition: transform $mm_transitionDuration $mm_transitionFunction;
}
html
{
	//	Left
	&.mm-opened .mm-menu.mm-fx-menu-zoom
	{
		transform: scale( $mm_zoomScaleDown, $mm_zoomScaleDown ) translate3d( -$mm_subpanelOffset, 0, 0 );
		transform-origin: left center;
	}
	&.mm-opening .mm-menu.mm-fx-menu-zoom
	{
		transform: scale( 1, 1 ) translate3d( 0%, 0, 0 );
	}

	//	Right
	&.mm-right
	{
		&.mm-opened .mm-menu.mm-fx-menu-zoom
		{
			transform: scale( $mm_zoomScaleDown, $mm_zoomScaleDown) translate3d( $mm_subpanelOffset, 0, 0 );
			transform-origin: right center;
		}
		&.mm-opening .mm-menu.mm-fx-menu-zoom
		{
			transform: scale( 1, 1 ) translate3d( 0%, 0, 0 );
		}
	}
}


//	Slide menu
.mm-menu.mm-fx-menu-slide
{
	transition: transform $mm_transitionDuration $mm_transitionFunction;
}
html
{
	//	Left
	&.mm-opened .mm-menu.mm-fx-menu-slide
	{
		//	transform: translate3d( -$mm_subpanelOffset, 0, 0 );
		@include mm_translate( -$mm_subpanelOffset, 0 );
	}
	&.mm-opening .mm-menu.mm-fx-menu-slide
	{
		//	transform: translate3d( 0%, 0, 0 );
		@include mm_translate( 0%, 0 );
	}

	//	Right
	&.mm-right
	{
		&.mm-opened .mm-menu.mm-fx-menu-slide
		{
			//	transform: translate3d( $mm_subpanelOffset, 0, 0 );
			@include mm_translate( $mm_subpanelOffset, 0 );
		}
		&.mm-opening .mm-menu.mm-fx-menu-slide
		{
			//	transform: translate3d( 0%, 0, 0 );
			@include mm_translate( 0%, 0 );
		}
	}
}


//	Fade menu
.mm-menu.mm-fx-menu-fade
{
	opacity: 0;
	transition: opacity $mm_transitionDuration $mm_transitionFunction;
}
html.mm-opening .mm-menu.mm-fx-menu-fade
{
	opacity: 1;
}


//	No effect panels
.mm-menu
{
	&.mm-fx-panels-none .mm-panel,
	.mm-fx-panels-none.mm-panel
	{
		transition-property: none;
		
		&.mm-subopened
		{
			@include mm_translate( 0, 0 );
		}
	}
}


//	Zoom panels
.mm-menu
{
	&.mm-fx-panels-zoom .mm-panel,
	.mm-fx-panels-zoom.mm-panel
	{
		transform-origin: left center;
		transform: scale( $mm_zoomScaleUp, $mm_zoomScaleUp ) translate3d( 100%, 0, 0 );

		&.mm-opened
		{
			transform: scale( 1, 1 ) translate3d( 0%, 0, 0 );
		}
		&.mm-subopened
		{
			transform: scale( $mm_zoomScaleDown, $mm_zoomScaleDown ) translate3d( -$mm_subpanelOffset, 0, 0 );
		}
	}
}


//	Slide panels
.mm-menu
{
	&.mm-fx-panels-slide-0 .mm-panel.mm-subopened,
	.mm-fx-panels-slide-0.mm-panel.mm-subopened
	{
		@include mm_translate( 0, 0 );
	}

	&.mm-fx-panels-slide-100 .mm-panel.mm-subopened,
	.mm-fx-panels-slide-100.mm-panel.mm-subopened
	{
		@include mm_translate( -100%, 0 );
	}
}


//	SlideUp panels
.mm-menu
{
	&.mm-fx-panels-slide-up .mm-panel,
	.mm-fx-panels-slide-up.mm-panel
	{
		@include mm_translate( 0, 100% );

		&.mm-opened
		{
			@include mm_translate( 0, 0 );
		}
		&.mm-subopened
		{
			@include mm_translate( 0, 0% );
		}
	}
}


//	Listitem delay
@mixin mm_effect_listitem_delay( $i )
{
	&:nth-child( #{$i} )
	{
		transition-delay: ( $i * 50ms );
	}
}
.mm-menu[class*="mm-fx-listitems-"] .mm-listview > li
{
	transition: none $mm_transitionDuration $mm_transitionFunction;

	@include mm_effect_listitem_delay( 1 );
	@include mm_effect_listitem_delay( 2 );
	@include mm_effect_listitem_delay( 3 );
	@include mm_effect_listitem_delay( 4 );
	@include mm_effect_listitem_delay( 5 );
	@include mm_effect_listitem_delay( 6 );
	@include mm_effect_listitem_delay( 7 );
	@include mm_effect_listitem_delay( 8 );
	@include mm_effect_listitem_delay( 9 );
	@include mm_effect_listitem_delay( 10 );
	@include mm_effect_listitem_delay( 11 );
	@include mm_effect_listitem_delay( 12 );
	@include mm_effect_listitem_delay( 13 );
	@include mm_effect_listitem_delay( 14 );
	@include mm_effect_listitem_delay( 15 );
}


//	Slide list items
.mm-menu.mm-fx-listitems-slide .mm-listview > li
{
	transition-property: transform, opacity;
	@include mm_translate( 50%, 0 );
	opacity: 0;
}
html.mm-opening .mm-menu.mm-fx-listitems-slide .mm-panel.mm-opened .mm-listview > li
{
	@include mm_translate( 0, 0 );
	opacity: 1;
}


//	Fade list items
.mm-menu.mm-fx-listitems-fade .mm-listview > li
{
	transition-property: opacity;
	opacity: 0;
}
html.mm-opening .mm-menu.mm-fx-listitems-fade .mm-panel.mm-opened .mm-listview > li
{
	opacity: 1;
}


//	Drop list items
.mm-menu.mm-fx-listitems-drop .mm-listview > li
{
	transition-property: opacity, top;
	opacity: 0;
	top: -25%;
}
html.mm-opening .mm-menu.mm-fx-listitems-drop .mm-panel.mm-opened .mm-listview > li
{
	opacity: 1;
	top: 0;
}