header.header {
  text-align: center;
  height: 133px;
  &:before {
    box-shadow: none;
  }
  div.branding {
    transform: scale(.85, .85);
    z-index: 5;
    position: relative;
    top: 5px;
    left: 0;
    padding: 0;
    background-color: transparent;
    a.logo {
      margin: 0;
      background: url(../Image/Mobile/Header/Logo.png) no-repeat;
      background-size: 100%;
      display: inline-block;
    }
    div.reserve {
      div.reserve-button {
        display: none;
      }
    }
  }
}

div.header-content div {
  &.empty-header {
    height: 50px;
  }
  &.csc-frame-default div.csc-textpic {
    width: 100%;
    position: relative;
    overflow: visible;
    //overflow-x: hidden;
    z-index: 2;
    margin-top: -19px;
    margin-bottom: -27px;
    img {
      box-sizing: border-box;
      background: $white;
      border-top: solid 3px $white;
      border-bottom: solid 3px $white;
      //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
      box-shadow: 0 1px 10px $color_black_30_approx;
      width: 100%;
      text-align: center;
    }
    div.csc-textpic-imagewrap {
      overflow: visible;
    }
  }
}