.room {
  //overflow: hidden;
  position: relative;
  margin-bottom: 30px;

  h1, h2 {
    font-size: 23px;
    display: inline-block;
    margin-bottom: 15px;
  }
  .room-price {
    line-height: 23px;
    padding: 0 0 12px;
    font-size: 26px;
    font-weight: bold;
    color: $color_peat_approx;
    @media (min-width: 500px) {
      float: right;
    }
  }

  .button {
    max-width: none;
  }

  .room-button-wrapper {
    margin-top: 12px;
    margin-bottom: 7px;
    display: flex;
    .button {
      margin-top: 0;
    }
  }

  .button-brown {
    color: $color_peat_approx;
    position: relative;
    left: -10px;
    z-index: -1;
    background-color: $color_soft_peach_approx;
    border-color: $color_soft_peach_approx;
  }

  .two-cols {
    max-width: 100%;
    max-height: 242px;
    display: inline-block;
    margin-bottom: 20px;
  }

  img {
    height: auto;
    border: 3px solid $color_soft_peach_approx;
    padding: 0px 0px;
    box-sizing: border-box;
  }
}

//##### - Room-Detail - #####
section.content div.main-content div.csc-default-introduction .room-detail {
  margin-top: 60px;
  .room-interior {
    li {
      padding: 9px 0 8px 35px;
      margin: 0 0 2px 0;
      background: url(../Image/Desktop/Icon/ListSpecial.png) no-repeat 0 1px;
    }
  }
  h1, h2 {
    margin: 10px 0 16px;
    @media (min-width: 500px) {
      margin: 25px 0 16px;
    }
  }
  .room-pictures h2 {
    display: block;
    .two-cols {
      max-width: 48%
    }
  }
  .room-price {
    padding-right: 12px;
    padding-bottom: 0;
    color: $color_peat_approx;
    font-size: 23px;
    margin-top: 28px;
    font-weight: normal;
  }
  h1 + .csc-default div.divider,
  h2 + .csc-default div.divider {
    margin-bottom: 13px;
    margin-top: 0;
  }
  div.divider {
    margin-bottom: 30px;
    margin-top: 26px;
  }
  p.green-bold, p.detail, span.detail {
    font-size: 15px;
    font-weight: 700;
    color: $color_limeade_approx;
    padding-bottom: 0;
  }
  p.bodytext {
    line-height: inherit;
    font-size: inherit;
    font-weight: inherit;
  }
}

.room-interior + .room-button-wrapper {
  margin-top: 20px;
}

//##### - Room-Previews - #####

section.content div.main-content .csc-default {
  &:first-of-type {
    .room-preview {
      margin-top: 30px;
    }
  }
}

section.content div.main-content div.csc-default-introduction {
  .room-preview {
    max-width: 360px;
    margin: 0 auto 30px;
    img {
      height: auto;
      width: 100%;
    }

    .button {
      margin-bottom: 0;
    }

    > h1, h2 {
      font-size: 16px;
      margin-bottom: 11px;
      line-height: 16px;
      margin-top: 0;
      text-transform: none;
      padding: 0;
      font-weight: bold;
    }

    .room-price {
      font-size: 16px;
      padding: 0;
      margin-bottom: 10px;
      line-height: 16px;
    }
  }

  .img-responsive {
    max-width: 100%;
    width: 100%;
  }

  .room-pictures {
    margin-bottom: 25px;
  }

  .csc-header {
    margin: 0;
  }
}