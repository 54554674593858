td {
  color: inherit;
  font: inherit;
  vertical-align: top;
}
th {
  color: inherit;
  font: inherit;
  vertical-align: top;
  text-align: left;
}