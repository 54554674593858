header {
  display: block;
  &.header {
    width: 100%;
    background-color: $color_white_linen_approx;
    border-bottom: 2px $white solid;
    position: relative;
    &:after {
      @extend %extend_1;
      width: 100%;
      height: 100%;
      background: url(../Image/Noise.png);
    }
    &:before {
      @extend %extend_1;
      width: 100%;
      border-bottom: 1px $color_silk_approx solid;
      box-shadow: 0 1px 10px $black_25;
    }
  }
}

.header-content img {
  margin: 0;
}

.navigation.clearfix {
  display: none;
}

header.header nav {
  &.main-navigation {
    ul li a {
      font-size: 16px;
    }
  }
}

@media (max-width: $screen-sm-max) {
  @import "Tablet";
}

@media (max-width: ($screen-xs-max)) {
  @import "Mobile";
}

@media(min-width: $screen-md) {
  @import "Desktop";
}

@media(min-width: $screen-lg) {
  @import "Desktop-large";
}