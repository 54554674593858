legend {
  padding: 0;
}

fieldset {
  border: 0;
  padding: 0;
}

:focus {
  outline: 0;
}

@media(min-width: 0) and(max-width: $screen-xs-max) {
  form {
    label {
      display: block;
      position: relative;
      margin-bottom: 9px;
    }

    input[type=text] {
      padding: 4px 10px;
      //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
      border-radius: 8px / 8px 8px 7px 7px;
      background-color: $white;
      //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
      box-shadow: 0 1px 1px $white_70, inset 1px 2px 4px $black_20;
      border: solid 1px $color_stonewall_30_approx;
    }

    textarea {
      padding: 4px 10px;
      //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
      border-radius: 8px / 8px 8px 7px 7px;
      background-color: $white;
      //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
      box-shadow: 0 1px 1px $white_70, inset 1px 2px 4px $black_20;
      border: solid 1px $color_stonewall_30_approx;
    }

    span.label {
      position: absolute;
      left: 10px;
      top: 5px;
      z-index: 1;
      color: $color_nobel_approx;
      font-style: italic;
      cursor: text;
    }

    div.radio {
      cursor: pointer;
      width: 18px;
      height: 18px;
      display: inline;
      display: inline-block;
      zoom: 1;
      vertical-align: middle;

      span {
        cursor: pointer;
        width: 18px;
        height: 18px;
        display: inline;
        display: inline-block;
        zoom: 1;
        text-align: center;
        background: url(../Image/Desktop/Icon/Radio.png) 0 0 no-repeat;

        &.checked {
          background: url(../Image/Desktop/Icon/RadioChecked.png) 0 0 no-repeat;
        }
      }

      input {
        cursor: pointer;
        width: 18px;
        height: 18px;
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
        opacity: 0;
        border: none;
        background: none;
        display: inline;
        display: inline-block;
        zoom: 1;
        text-align: center;
      }
    }
  }
}

@media(min-width: $screen-sm) and(max-width: $screen-sm-max) {
  form {
    label {
      display: block;
      position: relative;
      margin-bottom: 9px;
    }

    input[type=text] {
      padding: 4px 10px;
      //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
      border-radius: 8px / 8px 8px 7px 7px;
      background-color: $white;
      //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
      box-shadow: 0 1px 1px $white_70, inset 1px 2px 4px $black_20;
      border: solid 1px $color_stonewall_30_approx;
    }

    textarea {
      padding: 4px 10px;
      //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
      border-radius: 8px / 8px 8px 7px 7px;
      background-color: $white;
      //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
      box-shadow: 0 1px 1px $white_70, inset 1px 2px 4px $black_20;
      border: solid 1px $color_stonewall_30_approx;
    }

    span.label {
      position: absolute;
      left: 10px;
      top: 5px;
      z-index: 1;
      color: $color_nobel_approx;
      font-style: italic;
      cursor: text;
    }

    div.radio {
      cursor: pointer;
      width: 18px;
      height: 18px;
      display: inline;
      display: inline-block;
      zoom: 1;
      vertical-align: middle;

      span {
        cursor: pointer;
        width: 18px;
        height: 18px;
        display: inline;
        display: inline-block;
        zoom: 1;
        text-align: center;
        background: url(../Image/Desktop/Icon/Radio.png) 0 0 no-repeat;

        &.checked {
          background: url(../Image/Desktop/Icon/RadioChecked.png) 0 0 no-repeat;
        }
      }

      input {
        cursor: pointer;
        width: 18px;
        height: 18px;
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
        opacity: 0;
        border: none;
        background: none;
        display: inline;
        display: inline-block;
        zoom: 1;
        text-align: center;
      }
    }
  }
}

@media(min-width: $screen-md) {
  form {

    input[type=text] {
      padding: 4px 10px;
      border-radius: 8px / 8px 8px 7px 7px;
      background-color: $white;
      box-shadow: 0 1px 1px $white_70, inset 1px 2px 4px $black_20;
      border: solid 1px $color_stonewall_30_approx;
    }

    textarea {
      padding: 4px 10px;
      border-radius: 8px / 8px 8px 7px 7px;
      background-color: $white;
      box-shadow: 0 1px 1px $white_70, inset 1px 2px 4px $black_20;
      border: solid 1px $color_stonewall_30_approx;
    }

    span.label {
      position: absolute;
      left: 10px;
      top: 5px;
      z-index: 1;
      color: $color_nobel_approx;
      font-style: italic;
      cursor: text;
    }

    div.radio {
      cursor: pointer;
      width: 18px;
      height: 18px;
      display: inline;
      display: inline-block;
      zoom: 1;
      vertical-align: middle;

      span {
        cursor: pointer;
        width: 18px;
        height: 18px;
        display: inline;
        display: inline-block;
        zoom: 1;
        text-align: center;
        background: url(../Image/Desktop/Icon/Radio.png) 0 0 no-repeat;

        &.checked {
          background: url(../Image/Desktop/Icon/RadioChecked.png) 0 0 no-repeat;
        }
      }

      input {
        cursor: pointer;
        width: 18px;
        height: 18px;
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
        opacity: 0;
        border: none;
        background: none;
        display: inline;
        display: inline-block;
        zoom: 1;
        text-align: center;
      }
    }
  }
}

#kontaktformular > div:not(.form-group,.actions) {
  position: absolute;
}


#kontaktformular {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 90%;
  @media(min-width: $screen-sm-max) {
    width: 80%;
  }

  @media(min-width: $screen-md) {
    width: unset;
  }

  .form-group {
    width: 100%;
    margin-bottom: 9px;
    @media(min-width: $screen-md) {
      width: 30%;
    }

    label {
      display: none;
    }

    .input input {
      width: 100%;
    }

    .kontaktformular-salutation-label {
      display: block;
    }

    #kontaktformular-salutation, .checkbox {
      label {
        display: block;
      }

      .form-group {
        display: flex;
        margin-bottom: 0;

        .form-check-label {
          display: flex;
          flex-direction: row-reverse;
        }
      }
    }
  }

  .form-group-kontaktformular-salutation {
    display: flex;
  }

  .form-group-kontaktformular-salutation {
    .form-check{
      margin-left: 20px;
      label.form-check-label input {
        width: auto;
      }
    }
  }

  .form-group-kontaktformular-checkbox-price, .form-group-kontaktformular-checkbox-data{
    width: 100%;

    label.form-check-label input {
      width: auto;
    }
  }

  .form-group-kontaktformular-message {
    width: 100%;

    textarea {
      height: 150px;
      width: 100%;
    }
  }
}