header.header nav {
  &.breadcrumb-navigation {
    clear: both;
    top: -12px;
    margin-bottom: -45px;
    position: relative;
    z-index: 4;
    display: none;
    ul li {
      float: left;
      a {
        font-size: 11px;
        color: $color_peat_approx;
        text-decoration: underline;
        margin: 0 5px;
        &:hover {
          text-decoration: none;
        }
      }
      &.first a {
        margin-left: 0;
      }
      &.last a {
        text-decoration: none;
      }
    }
  }

  &.main-navigation {
    left: 202px;
    bottom: 7px;
    li {
      display: inline-block;
    }
  }
}