
@import "hamburger";

// import core
// variables in: mmenu/scss/variables
@import "mmenu/core/oncanvas/jquery.mmenu.oncanvas";
@import "mmenu/core/offcanvas/jquery.mmenu.offcanvas";
@import "mmenu/core/screenreader/jquery.mmenu.screenreader";
// first extensions
@import "mmenu/extensions";
// second addons
@import "mmenu/addons";


//Mobile menu css
@media (min-width: 0) and (max-width: 768px) {
  .mm-navbar {
    border-bottom: 1px solid $color_bone_approx;
  }
  
  .mm-title {
    font-size: 18px;
    font-weight: bold;
    line-height: 50px;
  }

  .mm-listview .mm-next {
    width: 60px;
  }

  .mm-navbar .mm-btn:first-child {
    border-right: 1px solid $color_bone_approx;
  }

  .mm-opened {
    .navigation-opener .label,
    header.header {
      display: none;
    }

    .mm-navbars-top {
      height: 70px;
    }
    .navigation-opener {
      margin-top: 10px;
    }

    .mm-hasnavbar-top-4 .mm-panels {
      top: 70px;
    }

    .mm-navbar-size-4 {
      height: 100px;
    }
  }



  //.mm-navbars-top {
  //  border: none;
  //}
}

