
@media(min-width: $screen-sm) and(max-width: $screen-sm-max) {
  div {
    &.contact {
      label {
        &.salutation-field-male {
          float: left;
          margin-right: 19px;
          cursor: pointer;
        }
        &.salutation-field-female {
          float: left;
          cursor: pointer;
        }
        &.firstname-field {
          float: left;
          width: 232px;
          margin-right: 20px;
          input {
            width: 210px;
          }
        }
        &.lastname-field {
          float: left;
          width: 232px;
          margin-right: 20px;
          margin-right: 0;
          input {
            width: 210px;
          }
        }
        &.street-field {
          float: left;
          width: 232px;
          margin-right: 20px;
          input {
            width: 210px;
          }
        }
        &.zipcode-field {
          float: left;
          width: 232px;
          margin-right: 20px;
          input {
            width: 210px;
          }
        }
        &.city-field {
          float: left;
          width: 232px;
          margin-right: 20px;
          margin-right: 0;
          input {
            width: 210px;
          }
        }
        &.company-field {
          float: left;
          width: 232px;
          margin-right: 20px;
          input {
            width: 210px;
          }
        }
        &.country-field {
          float: left;
          width: 232px;
          margin-right: 20px;
          input {
            width: 210px;
          }
        }
        &.section-field {
          float: left;
          width: 232px;
          margin-right: 20px;
          margin-right: 0;
          input {
            width: 210px;
          }
        }
        &.phone-field {
          float: left;
          width: 232px;
          margin-right: 20px;
          input {
            width: 210px;
          }
        }
        &.fax-field {
          float: left;
          width: 232px;
          margin-right: 20px;
          input {
            width: 210px;
          }
        }
        &.mobile-field {
          float: left;
          width: 232px;
          margin-right: 20px;
          margin-right: 0;
          input {
            width: 210px;
          }
        }
        &.email-field {
          float: left;
          width: 232px;
          margin-right: 20px;
          input {
            width: 210px;
          }
        }
        &.message-field {
          width: 740px;
          textarea {
            width: 718px;
            height: 200px;
            resize: none;
          }
        }
      }
      fieldset.salutation-field {
        float: left;
        width: 230px;
        margin-right: 20px;
        span {
          float: left;
          margin-right: 19px;
        }
      }
    }
  }
}

@media(min-width: $screen-md) {
  div {
    &.contact {
      label {
        &.salutation-field-male {
          float: left;
          margin-right: 19px;
          cursor: pointer;
        }
        &.salutation-field-female {
          float: left;
          cursor: pointer;
        }
        &.firstname-field {
          float: left;
          width: 232px;
          margin-right: 20px;
          input {
            width: 210px;
          }
        }
        &.lastname-field {
          float: left;
          width: 232px;
          margin-right: 20px;
          margin-right: 0;
          input {
            width: 210px;
          }
        }
        &.street-field {
          float: left;
          width: 232px;
          margin-right: 20px;
          input {
            width: 210px;
          }
        }
        &.zipcode-field {
          float: left;
          width: 232px;
          margin-right: 20px;
          input {
            width: 210px;
          }
        }
        &.city-field {
          float: left;
          width: 232px;
          margin-right: 20px;
          margin-right: 0;
          input {
            width: 210px;
          }
        }
        &.company-field {
          float: left;
          width: 232px;
          margin-right: 20px;
          input {
            width: 210px;
          }
        }
        &.country-field {
          float: left;
          width: 232px;
          margin-right: 20px;
          input {
            width: 210px;
          }
        }
        &.section-field {
          float: left;
          width: 232px;
          margin-right: 20px;
          margin-right: 0;
          input {
            width: 210px;
          }
        }
        &.phone-field {
          float: left;
          width: 232px;
          margin-right: 20px;
          input {
            width: 210px;
          }
        }
        &.fax-field {
          float: left;
          width: 232px;
          margin-right: 20px;
          input {
            width: 210px;
          }
        }
        &.mobile-field {
          float: left;
          width: 232px;
          margin-right: 20px;
          margin-right: 0;
          input {
            width: 210px;
          }
        }
        &.email-field {
          float: left;
          width: 232px;
          margin-right: 20px;
          input {
            width: 210px;
          }
        }
        &.message-field {
          width: 740px;
          textarea {
            width: 718px;
            height: 200px;
            resize: none;
          }
        }
      }
      fieldset.salutation-field {
        float: left;
        width: 230px;
        margin-right: 20px;
        span {
          float: left;
          margin-right: 19px;
        }
      }
    }
  }
}