//	Generic classes
.mm-hidden {
  display: none !important;
}

//	Container
.mm-wrapper {
  overflow-x: hidden;
  position: relative;
}

//	Menu
.mm-menu {
  box-sizing: border-box;
  background: inherit;
  display: block;
  padding: 0;
  margin: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0;
}

.navigation-opener-wrapper {
  background-color: $color_white_linen_approx;
  height: 120px;
}

.navigation-opener {
  margin-top: 20px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 100%;
  border: solid 1px $color_silk_approx;
  background-color: transparentize($color_bone_approx, .4);
  box-shadow: $white_70 0px 0px 0px 2px;
  .label {
    position: absolute;
    bottom: -23px;
    left: -16px;
    font-size: 15px;
    font-family: serif;
    text-transform: uppercase;
    color: $color_peat_approx;
  }
}

.hamburger {
  padding: 14px 1px 12px;
  width: 38px;
  &.is-active {
    padding: 14px 6px;
  }
}

.hamburger-inner,
.hamburger-inner::after,
.hamburger-inner::before {
  background-color: $color_olivetone_approx;
  height: 4px;
  width: 28px;
  //border-shadow: transparentize($color_donkey_brown_approx,0.5);
  border-radius: 0;
}

.hamburger-box {
  width: 28px;
}

.mm-listview>li>a,
.mm-listview>li>span {
  border-bottom: 1px solid $color_bone_approx;
  &:hover{
  color: $color_limeade_approx;
}
}

em.mm-counter+a.mm-next {
  border-left: transparent;
}

.mm-panels>.mm-panel.mm-hasnavbar {
  padding-top: 0;
}

.mm-subopened {

}