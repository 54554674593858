footer {
  position: relative;
  display: block;
  padding-top: 20px;
  margin-top: 20px;
  div.inner {
    width: 100%;
    max-width: 990px;
  }
  .meta .facebook-wrapper {
    color: $white;
    position: absolute;
    right: 0;
    top: 162px;
    width: 225px;
    max-width: none;
    background-color: $facebook_blue_light;
    border: solid 1px $facebook_blue;
    text-align: left;
    img {
      float: right;
    }
    &:hover {
      background-color: $facebook_blue_hover;
      border: solid 1px $facebook_blue_light;
    }
  }
  ul.teaser:first-of-type {
    margin-right: 40px;

    .button {
      margin-right: 0;
      width: initial;
      padding: 4px 30px;
    }
  }
  ul.teaser:not(:first-of-type) {
    h3 {
      margin-bottom: 20px;
    }
  }
  h3 {
    font-size: 18px;
    text-transform: uppercase;
    color: $white;
    font-weight: bold;
    font-family: $font_2;
    text-align: center;
    margin-top: 0;
    margin-bottom: 5px;
  }
  li img {
    width: 100%;
    height: auto;
  }
  .button {
    width: 100%;
    height: auto;
    margin-top: 20px;
  }
  .footer-button {
    display: inline-flex;
    width: 100%;
  }
  &.footer {
    width: 100%;
    background: $color_donkey_brown_approx;
    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: url(../Image/Noise.png);
    }
  }
}

@media(min-width: 0) and(max-width: $screen-xs-max) {

  footer.footer {
    height: 220px;
    .meta .facebook-wrapper {
      width: 220px;
      position: absolute;
      margin: 0;
      top: 170px;
      left: 15px;
      height: 28px;
      padding-top: 5px;
    }
    nav {
      display: none;
      &.meta {
        display: block;
        position: relative;
        z-index: 2;
        ul {
          width: 100%;
          li {
            a {
              border-bottom: 1px solid $color_almond_frost_approx;
              padding: 8px 15px 9px;
              color: $color_pot_pourri_approx;
              filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=90);
              opacity: .9;
              text-decoration: underline;
              margin-bottom: 4px;
              display: block;
              &:hover {
                text-decoration: none;
              }
            }
            &:last-child a {
              border-bottom: none;
            }
          }
        }
      }
    }
    ul.teaser {
      display: none;
    }
  }
}

@media(min-width: $screen-sm) and(max-width: $screen-sm-max) {
  footer.footer {
    height: 200px;
    nav {
      position: absolute;
      top: 9px;
      z-index: 2;
      right: 170px;
      display: none;
      &.meta {
        display: block;
        position: relative;
        z-index: 2;
        top: 0;
        right: auto;
        left: 0;
        ul {
          width: 100%;
          li {
            a {
              background: none;
              border-bottom: 1px solid $color_almond_frost_approx;
              padding: 8px 15px 9px;
            }
            &:last-child a {
              border-bottom: none;
            }
          }
        }
      }
      ul li a {
        color: $color_pot_pourri_approx;
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=90);
        opacity: .9;
        text-decoration: underline;
        margin-bottom: 4px;
        display: block;
        &:hover {
          text-decoration: none;
        }
      }
    }
    .meta {
      right: 0;
    }
    ul.teaser {
      position: relative;
      z-index: 2;
      display: none;
      li {
      }
    }
  }
  footer.footer {
    height: 220px;
    .meta .facebook-wrapper {
      width: 230px;
      position: absolute;
      margin: 0;
      top: 170px;
      left: 15px;
      height: 28px;
      padding-top: 5px;
    }
  }
}

@media(min-width: $screen-md) {
  footer.footer {
    height: 255px;
    nav {
      position: absolute;
      top: 25px;
      z-index: 2;
      right: 170px;
      ul li a {
        color: $color_pot_pourri_approx;
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=90);
        opacity: .9;
        text-decoration: underline;
        margin-bottom: 4px;
        display: block;
        &:hover {
          text-decoration: none;
        }
      }
    }
    .meta {
      right: 45px;
    }
    ul.teaser {
      position: relative;
      z-index: 2;
      li {

      }
    }
  }
}

