.menu-has-sub .fa {
  color: $color_donkey_brown_approx;
  font-size: 23px;
  position: relative;
  right: 5px;
  top: -3px;
}

section.content nav.sub-navigation {
  ul li.active > a {
    background-color: $color_dawn_pink_approx;
  }

  li.menu-has-sub > ul > li > a {
    padding-left: 20px;
  }
}

@media(min-width: $screen-sm) {
  @import "Desktop";
}

@media (max-width: $screen-sm-max) {
  @import "Tablet";
}

@media(max-width: $screen-xs-max) {
  @import "Mobile";
}

#mobile-menu {
  display: none;
}