section {
  &.content {
    margin: 40px 0 0;
    div {
      &.main-content {
        margin: 0 18px;
        min-height: 400px;
        width: calc(100% - 286px);
        float: right;
        div {
          &.empty-main-content {
            height: 200px;
          }
          &.csc-default {
            clear: both;
          }
          &.csc-default-introduction {
            h1 {
              &:after {
                color: $color_judge_gray_approx;
                filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=10);
                opacity: .1;
                height: 6px;
              }
            }
            h2 {
              color: $color_peat_approx;
              font-size: 26px;
              line-height: 26px;
              &:after {
                color: $color_judge_gray_approx;
                filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=10);
                opacity: .1;
                height: 6px;
              }
            }
            h3 {
              color: $color_peat_approx;
              font-size: 26px;
              line-height: 26px;
              &:after {
                color: $color_judge_gray_approx;
                filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=10);
                opacity: .1;
                height: 6px;
              }
            }
            p {

            }
          }
          &.csc-half-size {
            float: left;
            clear: none;
            width: 46%;
            min-width: 310px;
            margin-right: 15px;
            div.csc-textpic-border div.csc-textpic-image span.image:after {
              bottom: -7px;
              //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
              transform: scale(.5, .5);
            }
          }
          &.csc-textpic-border {
            div.csc-textpic-image span.image {
              position: relative;
              display: block;
              &:after {
                display: block;
                content: "";
                position: absolute;
                bottom: -10px;
                left: 50%;
                margin-left: -335px;
                width: 670px;
                height: 10px;
                //background: url(../Image/Desktop/Content/Shadow.png) no-repeat;
              }
            }
            .csc-textpic-firstcol {

            }
            &.csc-textpic-intext-right div.csc-textpic-image span.image:after {
              bottom: -7px;
              //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
              transform: scale(.5, .5);
            }
            &.csc-textpic-intext-left div.csc-textpic-image span.image:after {
              bottom: -7px;
              //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
              transform: scale(.5, .5);
            }
            &.csc-textpic-intext-right-nowrap div.csc-textpic-image span.image:after {
              bottom: -7px;
              //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
              transform: scale(.5, .5);
            }
            &.csc-textpic-intext-left-nowrap div.csc-textpic-image span.image:after {
              bottom: -7px;
              //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
              transform: scale(.5, .5);
            }
          }
          &.csc-textpic-intext-left-nowrap {
            img {
              float: left;
            }
            div.csc-textpic-text {
              margin-left: 380px;
            }
          }
          &.csc-textpic-intext-right-nowrap {
            img {
              float: right;
            }
            div.csc-textpic-text {
              margin-right: 380px;
            }
          }
          &.csc-textpic-imagecolumn span.image:after {
            display: none !important;
          }
        }
        ul {
          li {
            padding: 0 0 0 15px;
            background: url(../Image/Desktop/Icon/ListLevel1.png) no-repeat 0 6px;
            margin: 0 0 9px 0;
            ul {
              margin: 9px 0 0;
              li {
                background-image: url(../Image/Desktop/Icon/ListLevel2.png);
              }
            }
          }
          &.csc-menu li.active a {
            font-weight: bold;
          }
          &.special li {
            padding: 8px 0 8px 35px;
            margin: 0 0 2px 0;
            background: url(../Image/Desktop/Icon/ListSpecial.png) no-repeat 0 1px;
          }
          &.links li {
            background: url(../Image/Desktop/Icon/ListLinks.png) no-repeat 0 3px;
            a {
              text-decoration: underline;
              &:hover {
                text-decoration: none;
              }
            }
          }
        }
        h1 {
          position: relative;
          font-family: $font_0;
          font-size: 20px;
          font-weight: normal;
          line-height: 20px;
          color: $color_peat_approx;
          text-transform: uppercase;
          padding: 0 0 12px 0;
          margin: 0 0 6px 0;
          &:after {
            content: attr(data-title);
            position: absolute;
            left: 0;
            top: 0;
            display: block;
            width: 100%;
            height: 5px;
            //overflow: hidden;
            color: $color_judge_gray_approx;
            filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=30);
            opacity: .3;
          }
          a {
            color: $color_peat_approx;
          }
        }
        h2 {
          position: relative;
          font-family: $font_0;
          font-size: 20px;
          font-weight: normal;
          line-height: 20px;
          color: $color_peat_approx;
          text-transform: uppercase;
          padding: 0 0 12px 0;
          margin: 0 0 6px 0;
          &:after {
            content: attr(data-title);
            position: absolute;
            left: 0;
            top: 0;
            display: block;
            width: 100%;
            height: 5px;
            //overflow: hidden;
            color: $color_judge_gray_approx;
            filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=30);
            opacity: .3;
          }
          a {
            color: $color_peat_approx;
          }
        }
        h3 {
          position: relative;
          font-family: $font_0;
          font-size: 20px;
          font-weight: normal;
          line-height: 20px;
          color: $color_peat_approx;
          text-transform: uppercase;
          padding: 0 0 12px 0;
          margin: 0 0 6px 0;
          &:after {
            content: attr(data-title);
            position: absolute;
            left: 0;
            top: 0;
            display: block;
            width: 100%;
            height: 5px;
            //overflow: hidden;
            color: $color_judge_gray_approx;
            filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=30);
            opacity: .3;
          }
          a {
            color: $color_peat_approx;
          }
        }
        h4 {
          font-weight: bold;
          margin-bottom: 9px;
        }
        h5 {
          font-weight: bold;
          margin-bottom: 9px;
        }
        caption.csc-textpic-caption {
          background: $color_soft_peach_approx;
          margin: 0 1px 0 0;
          padding: 3px 9px 8px;
        }
        table.contenttable {
          width: 100%;
          border-collapse: collapse;
          th {
            font-size: 14px;
            font-weight: bold;
            color: $color_olivetone_approx;
            padding: 8px 12px;
            border-right: 1px solid $color_olivetone_approx;
            position: relative;
            &:last-child {
              border-right: none;
            }
          }
          td {
            padding: 8px 12px;
            border-right: 1px solid $color_olivetone_approx;
            position: relative;
            &:last-child {
              border-right: none;
            }
          }
          tr {
            &:nth-child(even) {
              background: $white;
            }
            &:nth-child(odd) {
              background: $color_kidnapper_approx;
            }
          }
          thead tr {
            background: $white !important;
          }
        }
      }
      &.csc-default {
        margin: 0;
      }
      &.divider {
        border-top: 1px solid $color_dawn_pink_approx;
        height: 1px;
        hr {
          display: none;
        }
      }
    }
    nav.sub-navigation {
      margin: 40px 0 35px 18px;
      > ul {
        top: 45px;
      }
    }
    aside.left-content {
      width: 100%;
      .csc-default {
        margin: 0 0 30px 2%;
      }
      .csc-header {
        width: 100%;
      }
      > div {
        float: left;
        display: inline-block;
        width: 44%;
      }
    }
    p.green-bold, p.detail, span.detail {
      font-size: 14px;
      font-weight: bold;
      color: $color_pistachio_approx;
      padding-bottom: 3px;
    }
  }
}

div {
  &.inner {
    max-width: 990px;
  }
}

body {
  &.subpage-template section.content {
    div.main-content {
      //width: 93%;
      //float: left;
      //margin: 0 3%;
    }
    aside.left-content {
      clear: both;
    }
  }
}

section.content nav.sub-navigation {
  margin: 40px 0 35px 18px;
}

section.content aside.left-content .bestpreis-sign {
  padding-left: 18px;
  left: 0;
}

.startpage-template .bestpreis-sign {
  display: none;
}

.startpage-template section.content {
  margin-top: 0;
  div.main-content {
    width: 95%;
    margin-right: 20px;
    div.csc-half-size {
      margin-right: 3%;
    }
    div.csc-half-size-last {
      margin-right: 0;
    }
    img {
      width: 100%;
      height: auto;
      max-height: none;
    }
  }
}

.startpage-template div.main-content > .csc-default:first-of-type h1 {
  margin-top: -5px;
}

.bestpreis-sign {
  display: none;
}