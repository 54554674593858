h1,h2,h3,h4,h5,h6 {
  margin-bottom: 7px;
  font-weight: normal;
}

i {
  font-style: italic;
}

p {

}

a {
  font: inherit;
  text-decoration: none;
  color: $color_pistachio_approx;
  &:hover {
    color: $color_olivetone_approx;
  }
}


ul {
  margin: 0;
  padding: 0;
  list-style: none;
  li {
    list-style: none;
  }
}

ol {
  margin: 0;
  padding: 0;
  list-style: none;
  li {
    list-style: none;
  }
}

strong, b {
  font-weight: bold;
}

sub {
  font-size: 9px;
}

sup {
  font-size: 9px;
}

em {
  font-weight: inherit;
  font-style: italic;
}

small {
  font-size: 9px;
}

.img-responsive {
  max-width: 100%;
  height: auto;
}