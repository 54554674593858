.breadcrumb-navigation {
  display: none
}

section {
  &.content {
    margin-top: 20px;
    div {
      &.main-content {
        margin: 0 auto;
        margin-top: 40px;
        width: 90%;
        float: none;
        div.csc-half-size {
          margin: 40px auto 0;
          &:first-of-type {
            margin-top: 60px;
          }
        }
        div {
          &.empty-main-content {
            height: 200px;
          }
          &.csc-default {
            clear: both;
          }
          &.csc-default-introduction {
            h1 {

              &:after {
                color: $color_judge_gray_approx;
                filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=10);
                opacity: .1;
                height: 6px;
              }
            }
            h2 {
              color: $color_peat_approx;
              font-size: 24px;
              line-height: 24px;
              &:after {
                color: $color_judge_gray_approx;
                filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=10);
                opacity: .1;
                height: 6px;
              }
            }
            h3 {
              color: $color_peat_approx;
              font-size: 26px;
              line-height: 26px;
              &:after {
                color: $color_judge_gray_approx;
                filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=10);
                opacity: .1;
                height: 6px;
              }
            }
            p {

            }
          }
          &.csc-textpic-imagewrap {
            margin-left: 0;
          }
          &.csc-textpic-intext-left-nowrap div {
            &.csc-textpic-imagewrap {
              float: none;
            }
            &.csc-textpic-text {
              margin-left: 0;
            }
          }
          &.csc-textpic-intext-right-nowrap div {
            &.csc-textpic-imagewrap {
              float: none;
            }
            &.csc-textpic-text {
              margin-right: 0;
            }
          }
          &.csc-textpic-border div.csc-textpic-image span.image {
            position: relative;
            display: block;
            img {
              margin-bottom: 10px;
            }
          }
        }
        caption.csc-textpic-caption {
          background: $color_soft_peach_approx;
          margin: 0 1px 0 0;
          padding: 3px 9px 8px;
        }
        table.contenttable {
          width: 100%;
          border-collapse: collapse;
          th {
            font-size: 14px;
            font-weight: bold;
            color: $color_olivetone_approx;
            padding: 8px 12px;
            border-right: 1px solid $color_olivetone_approx;
            position: relative;
            &:after {
              content: "";
              display: block;
              position: absolute;
              right: 1px;
              top: 0;
              height: 100%;
              width: 5px;
              background: $color_cararra_approx;
              border-right: 1px solid $color_tana_approx;
            }
            &:last-child {
              border-right: none;
              &:after {
                display: none;
              }
            }
          }
          td {
            padding: 8px 12px;
            border-right: 1px solid $color_olivetone_approx;
            position: relative;
            &:after {
              content: "";
              display: block;
              position: absolute;
              right: 1px;
              top: 0;
              height: 100%;
              width: 5px;
              background: $color_cararra_approx;
              border-right: 1px solid $color_tana_approx;
            }
            &:last-child {
              border-right: none;
              &:after {
                display: none;
              }
            }
          }
          tr {
            &:nth-child(even) {
              background: $white;
            }
            &:nth-child(odd) {
              background: $color_kidnapper_approx;
            }
          }
          thead tr {
            background: $white !important;
          }
        }
      }
      &.csc-default {
        margin: 0 0 16px 0;
      }
      &.divider {
        border-top: 1px solid $color_dawn_pink_approx;
        height: 1px;
        hr {
          display: none;
        }
      }
    }
    nav.sub-navigation {
      display: none;
    }
    aside.left-content {
      display: none;
    }
    p.green-bold, p.detail, span.detail {
      font-size: 14px;
      font-weight: bold;
      color: $color_pistachio_approx;
      padding-bottom: 3px;
    }
  }
  .content.main-content {
    p {
      margin-bottom: 9px;
      a {
        text-decoration: none;
        font-weight: bold;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    h1 {
      position: relative;
      font-family: $font_0;
      font-size: 20px;
      font-weight: normal;
      line-height: 20px;
      color: $color_peat_approx;
      text-transform: uppercase;
      padding: 0 0 12px 0;
      margin: 0 0 6px 0;
      &:after {
        content: attr(data-title);
        position: absolute;
        left: 0;
        top: 0;
        display: block;
        width: 100%;
        height: 5px;
        //overflow: hidden;
        color: $color_judge_gray_approx;
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=30);
        opacity: .3;
      }
      a {
        color: $color_peat_approx;
      }
    }
    h2 {
      position: relative;
      font-family: $font_0;
      font-size: 20px;
      font-weight: normal;
      line-height: 20px;
      color: $color_peat_approx;
      text-transform: uppercase;
      padding: 0 0 12px 0;
      margin: 0 0 6px 0;
      &:after {
        content: attr(data-title);
        position: absolute;
        left: 0;
        top: 0;
        display: block;
        width: 100%;
        height: 5px;
        //overflow: hidden;
        color: $color_judge_gray_approx;
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=30);
        opacity: .3;
      }
      a {
        color: $color_peat_approx;
      }
    }
    h3 {
      position: relative;
      font-family: $font_0;
      font-size: 20px;
      font-weight: normal;
      line-height: 20px;
      color: $color_peat_approx;
      text-transform: uppercase;
      padding: 0 0 12px 0;
      margin: 0 0 6px 0;
      &:after {
        content: attr(data-title);
        position: absolute;
        left: 0;
        top: 0;
        display: block;
        width: 100%;
        height: 5px;
        //overflow: hidden;
        color: $color_judge_gray_approx;
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=30);
        opacity: .3;
      }
      a {
        color: $color_peat_approx;
      }
    }
    h4 {
      font-weight: bold;
      margin-bottom: 9px;
    }
    h5 {
      font-weight: bold;
      margin-bottom: 9px;
    }
    ul {
      li {
        padding: 0 0 0 15px;
        background: url(../Image/Desktop/Icon/ListLevel1.png) no-repeat 0 6px;
        margin: 0 0 18px 0;
        ul {
          margin: 18px 0 0;
          li {
            background-image: url(../Image/Desktop/Icon/ListLevel2.png);
          }
        }
      }
      &.special li {
        padding: 8px 0 8px 35px;
        margin: 0 0 2px 0;
        background: url(../Image/Desktop/Icon/ListSpecial.png) no-repeat 0 1px;
      }
      &.links li {
        background: url(../Image/Desktop/Icon/ListLinks.png) no-repeat 0 3px;
        a {
          text-decoration: underline;
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }
}

.startpage-template {
  section.content div.main-content div.csc-default {
    &:not(:first-of-type) {
      width: 100%;
    }
    img {
      width: 100%;
    }
  }
}

.startpage-template div.main-content>.csc-default:first-of-type h1 {
  margin-top: 10px;
}

.bestpreis-sign {
  display: none;
}