div.pp_default .pp_top, div.pp_default .pp_top .pp_middle, div.pp_default .pp_top .pp_left, div.pp_default .pp_top .pp_right, div.pp_default .pp_bottom, div.pp_default .pp_bottom .pp_left, div.pp_default .pp_bottom .pp_middle, div.pp_default .pp_bottom .pp_right {
    height: 13px
}

div.pp_default .pp_top .pp_left {
    background: url(../images/prettyPhoto/default/sprite.png) -78px -93px no-repeat
}

div.pp_default .pp_top .pp_middle {
    background: url(../images/prettyPhoto/default/sprite_x.png) top left repeat-x
}

div.pp_default .pp_top .pp_right {
    background: url(../images/prettyPhoto/default/sprite.png) -112px -93px no-repeat
}

div.pp_default .pp_content .ppt {
    color: #f8f8f8
}

div.pp_default .pp_content_container .pp_left {
    background: url(../images/prettyPhoto/default/sprite_y.png) -7px 0 repeat-y;
    padding-left: 13px
}

div.pp_default .pp_content_container .pp_right {
    background: url(../images/prettyPhoto/default/sprite_y.png) top right repeat-y;
    padding-right: 13px
}

div.pp_default .pp_next:hover {
    background: url(../images/prettyPhoto/default/sprite_next.png) center right no-repeat;
    cursor: pointer
}

div.pp_default .pp_previous:hover {
    background: url(../images/prettyPhoto/default/sprite_prev.png) center left no-repeat;
    cursor: pointer
}

div.pp_default .pp_expand {
    background: url(../images/prettyPhoto/default/sprite.png) 0 -29px no-repeat;
    cursor: pointer;
    width: 28px;
    height: 28px
}

div.pp_default .pp_expand:hover {
    background: url(../images/prettyPhoto/default/sprite.png) 0 -56px no-repeat;
    cursor: pointer
}

div.pp_default .pp_contract {
    background: url(../images/prettyPhoto/default/sprite.png) 0 -84px no-repeat;
    cursor: pointer;
    width: 28px;
    height: 28px
}

div.pp_default .pp_contract:hover {
    background: url(../images/prettyPhoto/default/sprite.png) 0 -113px no-repeat;
    cursor: pointer
}

div.pp_default .pp_close {
    width: 30px;
    height: 30px;
    background: url(../images/prettyPhoto/default/sprite.png) 2px 1px no-repeat;
    cursor: pointer
}

div.pp_default .pp_gallery ul li a {
    background: url(../images/prettyPhoto/default/default_thumb.png) center center #f8f8f8;
    border: 1px solid #aaa
}

div.pp_default .pp_social {
    margin-top: 7px
}

div.pp_default .pp_gallery a.pp_arrow_previous, div.pp_default .pp_gallery a.pp_arrow_next {
    position: static;
    left: auto
}

div.pp_default .pp_nav .pp_play, div.pp_default .pp_nav .pp_pause {
    background: url(../images/prettyPhoto/default/sprite.png) -51px 1px no-repeat;
    height: 30px;
    width: 30px
}

div.pp_default .pp_nav .pp_pause {
    background-position: -51px -29px
}

div.pp_default a.pp_arrow_previous, div.pp_default a.pp_arrow_next {
    background: url(../images/prettyPhoto/default/sprite.png) -31px -3px no-repeat;
    height: 20px;
    width: 20px;
    margin: 4px 0 0
}

div.pp_default a.pp_arrow_next {
    left: 52px;
    background-position: -82px -3px
}

div.pp_default .pp_content_container .pp_details {
    margin-top: 5px
}

div.pp_default .pp_nav {
    clear: none;
    height: 30px;
    width: 110px;
    position: relative
}

div.pp_default .pp_nav .currentTextHolder {
    font-family: Georgia;
    font-style: italic;
    color: #999;
    font-size: 11px;
    left: 75px;
    line-height: 25px;
    position: absolute;
    top: 2px;
    margin: 0;
    padding: 0 0 0 10px
}

div.pp_default .pp_close:hover, div.pp_default .pp_nav .pp_play:hover, div.pp_default .pp_nav .pp_pause:hover, div.pp_default .pp_arrow_next:hover, div.pp_default .pp_arrow_previous:hover {
    opacity: 0.7
}

div.pp_default .pp_description {
    font-size: 11px;
    font-weight: 700;
    line-height: 14px;
    margin: 5px 50px 5px 0
}

div.pp_default .pp_bottom .pp_left {
    background: url(../images/prettyPhoto/default/sprite.png) -78px -127px no-repeat
}

div.pp_default .pp_bottom .pp_middle {
    background: url(../images/prettyPhoto/default/sprite_x.png) bottom left repeat-x
}

div.pp_default .pp_bottom .pp_right {
    background: url(../images/prettyPhoto/default/sprite.png) -112px -127px no-repeat
}

div.pp_default .pp_loaderIcon {
    background: url(../images/prettyPhoto/default/loader.gif) center center no-repeat
}

div.light_rounded .pp_top .pp_left {
    background: url(../images/prettyPhoto/light_rounded/sprite.png) -88px -53px no-repeat
}

div.light_rounded .pp_top .pp_right {
    background: url(../images/prettyPhoto/light_rounded/sprite.png) -110px -53px no-repeat
}

div.light_rounded .pp_next:hover {
    background: url(../images/prettyPhoto/light_rounded/btnNext.png) center right no-repeat;
    cursor: pointer
}

div.light_rounded .pp_previous:hover {
    background: url(../images/prettyPhoto/light_rounded/btnPrevious.png) center left no-repeat;
    cursor: pointer
}

div.light_rounded .pp_expand {
    background: url(../images/prettyPhoto/light_rounded/sprite.png) -31px -26px no-repeat;
    cursor: pointer
}

div.light_rounded .pp_expand:hover {
    background: url(../images/prettyPhoto/light_rounded/sprite.png) -31px -47px no-repeat;
    cursor: pointer
}

div.light_rounded .pp_contract {
    background: url(../images/prettyPhoto/light_rounded/sprite.png) 0 -26px no-repeat;
    cursor: pointer
}

div.light_rounded .pp_contract:hover {
    background: url(../images/prettyPhoto/light_rounded/sprite.png) 0 -47px no-repeat;
    cursor: pointer
}

div.light_rounded .pp_close {
    width: 75px;
    height: 22px;
    background: url(../images/prettyPhoto/light_rounded/sprite.png) -1px -1px no-repeat;
    cursor: pointer
}

div.light_rounded .pp_nav .pp_play {
    background: url(../images/prettyPhoto/light_rounded/sprite.png) -1px -100px no-repeat;
    height: 15px;
    width: 14px
}

div.light_rounded .pp_nav .pp_pause {
    background: url(../images/prettyPhoto/light_rounded/sprite.png) -24px -100px no-repeat;
    height: 15px;
    width: 14px
}

div.light_rounded .pp_arrow_previous {
    background: url(../images/prettyPhoto/light_rounded/sprite.png) 0 -71px no-repeat
}

div.light_rounded .pp_arrow_next {
    background: url(../images/prettyPhoto/light_rounded/sprite.png) -22px -71px no-repeat
}

div.light_rounded .pp_bottom .pp_left {
    background: url(../images/prettyPhoto/light_rounded/sprite.png) -88px -80px no-repeat
}

div.light_rounded .pp_bottom .pp_right {
    background: url(../images/prettyPhoto/light_rounded/sprite.png) -110px -80px no-repeat
}

div.dark_rounded .pp_top .pp_left {
    background: url(../images/prettyPhoto/dark_rounded/sprite.png) -88px -53px no-repeat
}

div.dark_rounded .pp_top .pp_right {
    background: url(../images/prettyPhoto/dark_rounded/sprite.png) -110px -53px no-repeat
}

div.dark_rounded .pp_content_container .pp_left {
    background: url(../images/prettyPhoto/dark_rounded/contentPattern.png) top left repeat-y
}

div.dark_rounded .pp_content_container .pp_right {
    background: url(../images/prettyPhoto/dark_rounded/contentPattern.png) top right repeat-y
}

div.dark_rounded .pp_next:hover {
    background: url(../images/prettyPhoto/dark_rounded/btnNext.png) center right no-repeat;
    cursor: pointer
}

div.dark_rounded .pp_previous:hover {
    background: url(../images/prettyPhoto/dark_rounded/btnPrevious.png) center left no-repeat;
    cursor: pointer
}

div.dark_rounded .pp_expand {
    background: url(../images/prettyPhoto/dark_rounded/sprite.png) -31px -26px no-repeat;
    cursor: pointer
}

div.dark_rounded .pp_expand:hover {
    background: url(../images/prettyPhoto/dark_rounded/sprite.png) -31px -47px no-repeat;
    cursor: pointer
}

div.dark_rounded .pp_contract {
    background: url(../images/prettyPhoto/dark_rounded/sprite.png) 0 -26px no-repeat;
    cursor: pointer
}

div.dark_rounded .pp_contract:hover {
    background: url(../images/prettyPhoto/dark_rounded/sprite.png) 0 -47px no-repeat;
    cursor: pointer
}

div.dark_rounded .pp_close {
    width: 75px;
    height: 22px;
    background: url(../images/prettyPhoto/dark_rounded/sprite.png) -1px -1px no-repeat;
    cursor: pointer
}

div.dark_rounded .pp_description {
    margin-right: 85px;
    color: #fff
}

div.dark_rounded .pp_nav .pp_play {
    background: url(../images/prettyPhoto/dark_rounded/sprite.png) -1px -100px no-repeat;
    height: 15px;
    width: 14px
}

div.dark_rounded .pp_nav .pp_pause {
    background: url(../images/prettyPhoto/dark_rounded/sprite.png) -24px -100px no-repeat;
    height: 15px;
    width: 14px
}

div.dark_rounded .pp_arrow_previous {
    background: url(../images/prettyPhoto/dark_rounded/sprite.png) 0 -71px no-repeat
}

div.dark_rounded .pp_arrow_next {
    background: url(../images/prettyPhoto/dark_rounded/sprite.png) -22px -71px no-repeat
}

div.dark_rounded .pp_bottom .pp_left {
    background: url(../images/prettyPhoto/dark_rounded/sprite.png) -88px -80px no-repeat
}

div.dark_rounded .pp_bottom .pp_right {
    background: url(../images/prettyPhoto/dark_rounded/sprite.png) -110px -80px no-repeat
}

div.dark_rounded .pp_loaderIcon {
    background: url(../images/prettyPhoto/dark_rounded/loader.gif) center center no-repeat
}

div.dark_square .pp_left, div.dark_square .pp_middle, div.dark_square .pp_right, div.dark_square .pp_content {
    background: #000
}

div.dark_square .pp_description {
    color: #fff;
    margin: 0 85px 0 0
}

div.dark_square .pp_loaderIcon {
    background: url(../images/prettyPhoto/dark_square/loader.gif) center center no-repeat
}

div.dark_square .pp_expand {
    background: url(../images/prettyPhoto/dark_square/sprite.png) -31px -26px no-repeat;
    cursor: pointer
}

div.dark_square .pp_expand:hover {
    background: url(../images/prettyPhoto/dark_square/sprite.png) -31px -47px no-repeat;
    cursor: pointer
}

div.dark_square .pp_contract {
    background: url(../images/prettyPhoto/dark_square/sprite.png) 0 -26px no-repeat;
    cursor: pointer
}

div.dark_square .pp_contract:hover {
    background: url(../images/prettyPhoto/dark_square/sprite.png) 0 -47px no-repeat;
    cursor: pointer
}

div.dark_square .pp_close {
    width: 75px;
    height: 22px;
    background: url(../images/prettyPhoto/dark_square/sprite.png) -1px -1px no-repeat;
    cursor: pointer
}

div.dark_square .pp_nav {
    clear: none
}

div.dark_square .pp_nav .pp_play {
    background: url(../images/prettyPhoto/dark_square/sprite.png) -1px -100px no-repeat;
    height: 15px;
    width: 14px
}

div.dark_square .pp_nav .pp_pause {
    background: url(../images/prettyPhoto/dark_square/sprite.png) -24px -100px no-repeat;
    height: 15px;
    width: 14px
}

div.dark_square .pp_arrow_previous {
    background: url(../images/prettyPhoto/dark_square/sprite.png) 0 -71px no-repeat
}

div.dark_square .pp_arrow_next {
    background: url(../images/prettyPhoto/dark_square/sprite.png) -22px -71px no-repeat
}

div.dark_square .pp_next:hover {
    background: url(../images/prettyPhoto/dark_square/btnNext.png) center right no-repeat;
    cursor: pointer
}

div.dark_square .pp_previous:hover {
    background: url(../images/prettyPhoto/dark_square/btnPrevious.png) center left no-repeat;
    cursor: pointer
}

div.light_square .pp_expand {
    background: url(../images/prettyPhoto/light_square/sprite.png) -31px -26px no-repeat;
    cursor: pointer
}

div.light_square .pp_expand:hover {
    background: url(../images/prettyPhoto/light_square/sprite.png) -31px -47px no-repeat;
    cursor: pointer
}

div.light_square .pp_contract {
    background: url(../images/prettyPhoto/light_square/sprite.png) 0 -26px no-repeat;
    cursor: pointer
}

div.light_square .pp_contract:hover {
    background: url(../images/prettyPhoto/light_square/sprite.png) 0 -47px no-repeat;
    cursor: pointer
}

div.light_square .pp_close {
    width: 75px;
    height: 22px;
    background: url(../images/prettyPhoto/light_square/sprite.png) -1px -1px no-repeat;
    cursor: pointer
}

div.light_square .pp_nav .pp_play {
    background: url(../images/prettyPhoto/light_square/sprite.png) -1px -100px no-repeat;
    height: 15px;
    width: 14px
}

div.light_square .pp_nav .pp_pause {
    background: url(../images/prettyPhoto/light_square/sprite.png) -24px -100px no-repeat;
    height: 15px;
    width: 14px
}

div.light_square .pp_arrow_previous {
    background: url(../images/prettyPhoto/light_square/sprite.png) 0 -71px no-repeat
}

div.light_square .pp_arrow_next {
    background: url(../images/prettyPhoto/light_square/sprite.png) -22px -71px no-repeat
}

div.light_square .pp_next:hover {
    background: url(../images/prettyPhoto/light_square/btnNext.png) center right no-repeat;
    cursor: pointer
}

div.light_square .pp_previous:hover {
    background: url(../images/prettyPhoto/light_square/btnPrevious.png) center left no-repeat;
    cursor: pointer
}

div.facebook .pp_top .pp_left {
    background: url(../images/prettyPhoto/facebook/sprite.png) -88px -53px no-repeat
}

div.facebook .pp_top .pp_middle {
    background: url(../images/prettyPhoto/facebook/contentPatternTop.png) top left repeat-x
}

div.facebook .pp_top .pp_right {
    background: url(../images/prettyPhoto/facebook/sprite.png) -110px -53px no-repeat
}

div.facebook .pp_content_container .pp_left {
    background: url(../images/prettyPhoto/facebook/contentPatternLeft.png) top left repeat-y
}

div.facebook .pp_content_container .pp_right {
    background: url(../images/prettyPhoto/facebook/contentPatternRight.png) top right repeat-y
}

div.facebook .pp_expand {
    background: url(../images/prettyPhoto/facebook/sprite.png) -31px -26px no-repeat;
    cursor: pointer
}

div.facebook .pp_expand:hover {
    background: url(../images/prettyPhoto/facebook/sprite.png) -31px -47px no-repeat;
    cursor: pointer
}

div.facebook .pp_contract {
    background: url(../images/prettyPhoto/facebook/sprite.png) 0 -26px no-repeat;
    cursor: pointer
}

div.facebook .pp_contract:hover {
    background: url(../images/prettyPhoto/facebook/sprite.png) 0 -47px no-repeat;
    cursor: pointer
}

div.facebook .pp_close {
    width: 22px;
    height: 22px;
    background: url(../images/prettyPhoto/facebook/sprite.png) -1px -1px no-repeat;
    cursor: pointer
}

div.facebook .pp_description {
    margin: 0 37px 0 0
}

div.facebook .pp_loaderIcon {
    background: url(../images/prettyPhoto/facebook/loader.gif) center center no-repeat
}

div.facebook .pp_arrow_previous {
    background: url(../images/prettyPhoto/facebook/sprite.png) 0 -71px no-repeat;
    height: 22px;
    margin-top: 0;
    width: 22px
}

div.facebook .pp_arrow_previous.disabled {
    background-position: 0 -96px;
    cursor: default
}

div.facebook .pp_arrow_next {
    background: url(../images/prettyPhoto/facebook/sprite.png) -32px -71px no-repeat;
    height: 22px;
    margin-top: 0;
    width: 22px
}

div.facebook .pp_arrow_next.disabled {
    background-position: -32px -96px;
    cursor: default
}

div.facebook .pp_nav {
    margin-top: 0
}

div.facebook .pp_nav p {
    font-size: 15px;
    padding: 0 3px 0 4px
}

div.facebook .pp_nav .pp_play {
    background: url(../images/prettyPhoto/facebook/sprite.png) -1px -123px no-repeat;
    height: 22px;
    width: 22px
}

div.facebook .pp_nav .pp_pause {
    background: url(../images/prettyPhoto/facebook/sprite.png) -32px -123px no-repeat;
    height: 22px;
    width: 22px
}

div.facebook .pp_next:hover {
    background: url(../images/prettyPhoto/facebook/btnNext.png) center right no-repeat;
    cursor: pointer
}

div.facebook .pp_previous:hover {
    background: url(../images/prettyPhoto/facebook/btnPrevious.png) center left no-repeat;
    cursor: pointer
}

div.facebook .pp_bottom .pp_left {
    background: url(../images/prettyPhoto/facebook/sprite.png) -88px -80px no-repeat
}

div.facebook .pp_bottom .pp_middle {
    background: url(../images/prettyPhoto/facebook/contentPatternBottom.png) top left repeat-x
}

div.facebook .pp_bottom .pp_right {
    background: url(../images/prettyPhoto/facebook/sprite.png) -110px -80px no-repeat
}

div.pp_pic_holder a:focus {
    outline: none
}

div.pp_overlay {
    background: #000;
    display: none;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 9500
}

div.pp_pic_holder {
    display: none;
    position: absolute;
    width: 100px;
    z-index: 10000
}

.pp_content {
    height: 40px;
    min-width: 40px
}

* html .pp_content {
    width: 40px
}

.pp_content_container {
    position: relative;
    text-align: left;
    width: 100%
}

.pp_content_container .pp_left {
    padding-left: 20px
}

.pp_content_container .pp_right {
    padding-right: 20px
}

.pp_content_container .pp_details {
    float: left;
    margin: 10px 0 2px
}

.pp_description {
    display: none;
    margin: 0
}

.pp_social {
    float: left;
    margin: 0
}

.pp_social .facebook {
    float: left;
    margin-left: 5px;
    width: 55px;
    overflow: hidden
}

.pp_social .twitter {
    float: left
}

.pp_nav {
    clear: right;
    float: left;
    margin: 3px 10px 0 0
}

.pp_nav p {
    float: left;
    white-space: nowrap;
    margin: 2px 4px
}

.pp_nav .pp_play, .pp_nav .pp_pause {
    float: left;
    margin-right: 4px;
    text-indent: -10000px
}

a.pp_arrow_previous, a.pp_arrow_next {
    display: block;
    float: left;
    height: 15px;
    margin-top: 3px;
    overflow: hidden;
    text-indent: -10000px;
    width: 14px
}

.pp_hoverContainer {
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2000
}

.pp_gallery {
    display: none;
    left: 50%;
    margin-top: -50px;
    position: absolute;
    z-index: 10000
}

.pp_gallery div {
    float: left;
    overflow: hidden;
    position: relative
}

.pp_gallery ul {
    float: left;
    height: 35px;
    position: relative;
    white-space: nowrap;
    margin: 0 0 0 5px;
    padding: 0
}

.pp_gallery ul a {
    border: 1px rgba(0, 0, 0, 0.5) solid;
    display: block;
    float: left;
    height: 33px;
    overflow: hidden
}

.pp_gallery ul a img {
    border: 0
}

.pp_gallery li {
    display: block;
    float: left;
    margin: 0 5px 0 0;
    padding: 0
}

.pp_gallery li.default a {
    background: url(../images/prettyPhoto/facebook/default_thumbnail.gif) 0 0 no-repeat;
    display: block;
    height: 33px;
    width: 50px
}

.pp_gallery .pp_arrow_previous, .pp_gallery .pp_arrow_next {
    margin-top: 7px !important
}

a.pp_next {
    background: url(../images/prettyPhoto/light_rounded/btnNext.png) 10000px 10000px no-repeat;
    display: block;
    float: right;
    height: 100%;
    text-indent: -10000px;
    width: 49%
}

a.pp_previous {
    background: url(../images/prettyPhoto/light_rounded/btnNext.png) 10000px 10000px no-repeat;
    display: block;
    float: left;
    height: 100%;
    text-indent: -10000px;
    width: 49%
}

a.pp_expand, a.pp_contract {
    cursor: pointer;
    display: none;
    height: 20px;
    position: absolute;
    right: 30px;
    text-indent: -10000px;
    top: 10px;
    width: 20px;
    z-index: 20000
}

a.pp_close {
    position: absolute;
    right: 0;
    top: 0;
    display: block;
    line-height: 22px;
    text-indent: -10000px
}

.pp_loaderIcon {
    display: block;
    height: 24px;
    left: 50%;
    position: absolute;
    top: 50%;
    width: 24px;
    margin: -12px 0 0 -12px
}

#pp_full_res {
    line-height: 1 !important
}

#pp_full_res .pp_inline {
    text-align: left
}

#pp_full_res .pp_inline p {
    margin: 0 0 15px
}

div.ppt {
    color: #fff;
    display: none;
    font-size: 17px;
    z-index: 9999;
    margin: 0 0 5px 15px
}

div.pp_default .pp_content, div.light_rounded .pp_content {
    background-color: #fff
}

div.pp_default #pp_full_res .pp_inline, div.light_rounded .pp_content .ppt, div.light_rounded #pp_full_res .pp_inline, div.light_square .pp_content .ppt, div.light_square #pp_full_res .pp_inline, div.facebook .pp_content .ppt, div.facebook #pp_full_res .pp_inline {
    color: #000
}

div.pp_default .pp_gallery ul li a:hover, div.pp_default .pp_gallery ul li.selected a, .pp_gallery ul a:hover, .pp_gallery li.selected a {
    border-color: #fff
}

div.pp_default .pp_details, div.light_rounded .pp_details, div.dark_rounded .pp_details, div.dark_square .pp_details, div.light_square .pp_details, div.facebook .pp_details {
    position: relative
}

div.light_rounded .pp_top .pp_middle, div.light_rounded .pp_content_container .pp_left, div.light_rounded .pp_content_container .pp_right, div.light_rounded .pp_bottom .pp_middle, div.light_square .pp_left, div.light_square .pp_middle, div.light_square .pp_right, div.light_square .pp_content, div.facebook .pp_content {
    background: #fff
}

div.light_rounded .pp_description, div.light_square .pp_description {
    margin-right: 85px
}

div.light_rounded .pp_gallery a.pp_arrow_previous, div.light_rounded .pp_gallery a.pp_arrow_next, div.dark_rounded .pp_gallery a.pp_arrow_previous, div.dark_rounded .pp_gallery a.pp_arrow_next, div.dark_square .pp_gallery a.pp_arrow_previous, div.dark_square .pp_gallery a.pp_arrow_next, div.light_square .pp_gallery a.pp_arrow_previous, div.light_square .pp_gallery a.pp_arrow_next {
    margin-top: 12px !important
}

div.light_rounded .pp_arrow_previous.disabled, div.dark_rounded .pp_arrow_previous.disabled, div.dark_square .pp_arrow_previous.disabled, div.light_square .pp_arrow_previous.disabled {
    background-position: 0 -87px;
    cursor: default
}

div.light_rounded .pp_arrow_next.disabled, div.dark_rounded .pp_arrow_next.disabled, div.dark_square .pp_arrow_next.disabled, div.light_square .pp_arrow_next.disabled {
    background-position: -22px -87px;
    cursor: default
}

div.light_rounded .pp_loaderIcon, div.light_square .pp_loaderIcon {
    background: url(../images/prettyPhoto/light_rounded/loader.gif) center center no-repeat
}

div.dark_rounded .pp_top .pp_middle, div.dark_rounded .pp_content, div.dark_rounded .pp_bottom .pp_middle {
    background: url(../images/prettyPhoto/dark_rounded/contentPattern.png) top left repeat
}

div.dark_rounded .currentTextHolder, div.dark_square .currentTextHolder {
    color: #c4c4c4
}

div.dark_rounded #pp_full_res .pp_inline, div.dark_square #pp_full_res .pp_inline {
    color: #fff
}

.pp_top, .pp_bottom {
    height: 20px;
    position: relative
}

* html .pp_top, * html .pp_bottom {
    padding: 0 20px
}

.pp_top .pp_left, .pp_bottom .pp_left {
    height: 20px;
    left: 0;
    position: absolute;
    width: 20px
}

.pp_top .pp_middle, .pp_bottom .pp_middle {
    height: 20px;
    left: 20px;
    position: absolute;
    right: 20px
}

* html .pp_top .pp_middle, * html .pp_bottom .pp_middle {
    left: 0;
    position: static
}

.pp_top .pp_right, .pp_bottom .pp_right {
    height: 20px;
    left: auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 20px
}

.pp_fade, .pp_gallery li.default a img {
    display: none
}