/*From Live-Page - compiled to .scss on 5th July 2017*/

#c33 {
  width: 100%;
}

#d21-quickbook {
  padding: 0;
  max-width: none;
}

#d21-headline-container{
  display: none!important;
}

#d21-quickbook {
  .field {
    border: 3px solid $color_donkey_brown_approx;
  }
  .hasDatepicker {
    border: 3px solid $color_donkey_brown_approx;
  }
  select {
    border: 3px solid $color_donkey_brown_approx;
  }
  #d21-book-button {
    background-color: $color_rio_grande_approx!important;
    color: $color_olivetone_approx;
    font-weight: bold;
    border-left: solid 1px $color_limeade_approx;
    border-bottom: solid 1px $color_limeade_approx;
    border-top: solid 1px $color_olivetone_approx;
    border-right: solid 1px $color_olivetone_approx;
    border-radius: 4px;
    &:hover {
      background-color: lighten($color_rio_grande_approx,10)!important;
    }
  }
  #d21-avail-result-container {
    color: $color_donkey_brown_approx;
    &.d21-avail {
      background-color: white;
    }
    > div > p {
      color: $color_donkey_brown_approx;
    }
  }
  .d21-pulse-btn {
    //vendor-prefix found
    //-webkit-animation-name: pulse-btn
    animation-name: pulse-btn;
    //vendor-prefix found
    //-webkit-animation-duration: 1.5s
    animation-duration: 1.5s;
    //vendor-prefix found
    //-webkit-animation-iteration-count: 1
    animation-iteration-count: 1;
    //vendor-prefix found
    //-webkit-animation-play-state: running
    animation-play-state: running;
    background-color: $color_pistachio_approx;
  }
  i:not(.minus):not(.plus):not(.delete) {
    background-color: $color_donkey_brown_approx!important;
  }
  input[type=submit]:not(.d21-pulse-btn) {
    background-color: $color_rio_grande_approx;
  }
}



#d21-save-occupancy {
  background-color: $color_rio_grande_approx;
  color: $color_olivetone_approx;
  font-weight: bold;
  border-left: solid 1px $color_limeade_approx;
  border-bottom: solid 1px $color_limeade_approx;
  border-top: solid 1px $color_olivetone_approx;
  border-right: solid 1px $color_olivetone_approx;
  border-radius: 4px;
  &:hover {
    background-color: lighten($color_rio_grande_approx,10)!important;
  }
}

.picker__day--selected {
  background: $color_pistachio_approx;
  &:hover {
    background: $color_pistachio_approx;
  }
}

.d21-tooltip-box {
  background-color: $color_donkey_brown_75_approx;
}

@-webkit-keyframes pulse-btn {
  0% {
    background-color: $color_donkey_brown_approx;
  }
  50% {
   transform: scaleX(1);
  }
  to {
    transform: scaleX(1);
    background-color: $color_rio_grande_approx;
  }
}

@keyframes pulse-btn {
  0% {
    background-color: $color_donkey_brown_approx;
  }
  50% {
   transform: scaleX(1);
  }
  to {
    transform: scaleX(1);
    background-color: $color_rio_grande_approx;
  }
}


@media (min-width: $screen-sm) {
  #d21-quickbook #d21-submit-container input {
    float: right;
    padding-left: 22px;
    padding-right: 22px;
    line-height: 28px;
    width: 50%;
  }
}

@media (min-width: $screen-md) {

  #d21-quickbook {
    width: 100%;
    max-width: none;
  }
//if not moved, reserve-tool-width is set to 75%, cause it looks better
  #c33 {
    width: 75%;
  }
}

