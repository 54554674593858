.button,
.download {
  display: inline-block;
  padding: 4px 20px 4px;
  font-family: $font_2;
  font-size: 14px;
  font-weight: bold;
  color: $color_olivetone_approx;
  text-align: center;
  text-decoration: none !important;
  //text-shadow: 1px 1px 0 $color_citron_approx;
  background-color: $color_rio_grande_approx;
  border-left: solid 1px $color_limeade_approx;
  border-bottom: solid 1px $color_limeade_approx;
  border-top: solid 1px $color_olivetone_approx;
  border-right: solid 1px $color_olivetone_approx;
  border-radius: 4px;
  background-size: 100%;
  cursor: pointer;
  max-width: 100%;
  &:hover {
    background-color: lighten($color_rio_grande_approx,10);
    color: $color_olivetone_approx!important;
  }
  &:active {
    background: lighten($color_rio_grande_approx,10);
    font-weight: bold;
  }
}

.internal-link {
  font-weight: bold;
  text-decoration: none;
  color: $color_limeade_approx;
  text-shadow: 0 -1px 0 $color_olivetone_approx;
  text-transform: uppercase;
  font-size: 12px;
  &:hover {
    color: $color_pistachio_approx;
  }
}

.button {
  margin: 6px auto 0;
  box-sizing: border-box;
}

.download {
  margin-top: 9px;
  display: block;
}


footer .download {
  margin: 9px auto 0;
}

.button-full-width {
  @extend .button;
  width: 100%;
  max-width: none;
  margin-bottom: 10px;
}

@media (max-width: $screen-sm-max) {
  .reserve-button .button {
    background: transparent;
    border: none;
  }
}