

@media(min-width: $screen-sm) and(max-width: $screen-sm-max) {
  div {
    &.newsletter label {
      &.type-field-subscribe {
        float: left;
        width: 103px;
        margin-right: 9px;
        cursor: pointer;
      }
      &.type-field-unsubscribe {
        float: left;
        width: 102px;
        cursor: pointer;
      }
      &.firstname-field {
        float: left;
        margin-right: 9px;
        input[type=text] {
          width: 79px;
        }
      }
      &.lastname-field {
        float: left;
        input[type=text] {
          width: 93px;
        }
      }
      &.email-field {
        float: left;
        input[type=text] {
          width: 203px;
        }
      }
    }
  }
}

@media(min-width: $screen-md) {
  div {
    &.newsletter label {
      &.type-field-subscribe {
        float: left;
        width: 103px;
        margin-right: 9px;
        cursor: pointer;
      }
      &.type-field-unsubscribe {
        float: left;
        width: 102px;
        cursor: pointer;
      }
      &.firstname-field {
        float: left;
        margin-right: 9px;
        input[type=text] {
          width: 79px;
        }
      }
      &.lastname-field {
        float: left;
        input[type=text] {
          width: 93px;
        }
      }
      &.email-field {
        float: left;
        input[type=text] {
          width: 203px;
        }
      }
    }
  }
}